import * as React from 'react';
import { Button, Col, Row, Tabs, Typography, notification, Modal, Form, Input } from 'antd';
import CenterContainer from '../Layout/CenterContainer';
import './AccountPage.scss';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  connectSocial,
  linkWallet,
  logout,
  userInfoSelector,
  loginUser,
  userFormErrorMessageSelector, setUserFormErrorMessage, changeUsername
} from '../UserForm/UseSlice';
import {
  CheckCircleOutlined,
  CopyOutlined,
  FacebookOutlined
} from '@ant-design/icons';
import SocialButton from '../SocialButton';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useEffect, useState } from 'react';
import OmsText from '../CustomComponent/OmsText';
import OmsInput from '../CustomComponent/OmsInput';
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton
} from 'react-share';
import {
  API_User_UnLinkWallet
} from 'helpers/config';
import * as CommonHelper from 'helpers/common';
import { affTiersSelector } from '../../app/AppConfigSlice';
import { useNavigate } from 'react-router-dom';
import FormItem from 'antd/es/form/FormItem';
import OmsModal from '../CustomComponent/OmsModal';
import { useOmsWeb3 } from '../../hooks/UseOmsWeb3';
import WalletConnectLogin from '../UserForm/WalletConnectLogin';
import WalletInjectLogin from '../UserForm/WalletInjectLogin';

const {Title, Link} = Typography;
const baseUrl = process.env.REACT_APP_APP_BASE_DOMAIN;
const {confirm} = Modal;

interface AccountTypeProps extends React.HTMLAttributes <HTMLDivElement> {
}

const AccountPage = (props: AccountTypeProps) => {
  const {account, provider, isActive, deactivate} = useOmsWeb3();
  const userInfo = useAppSelector(userInfoSelector);
  const dispatch = useAppDispatch();
  const RefID = userInfo.uid ? userInfo.uid.slice(-8).toUpperCase() : undefined;
  const [refCodeCopied, setRefCodeCopied] = useState<boolean>(false);
  const [refLinkCopied, setRefLinkCopied] = useState<boolean>(false);
  const [, setUpdateNow] = useState(0);
  const affTiers = useAppSelector(affTiersSelector);
  const errorMessage = useAppSelector(userFormErrorMessageSelector);
  const navigate = useNavigate();

  const refCodeCopyIcon = refCodeCopied && <CheckCircleOutlined className={'copied'}/> || <CopyOutlined/>;
  const refCodeLinkIcon = refLinkCopied && <CheckCircleOutlined className={'copied'}/> || <CopyOutlined/>;
  const points = userInfo?.points || 0;
  const tier = affTiers && userInfo.points && affTiers.find(item => points >= item.start_point && points <= item.end_point) || {
    _id: 0,
    claim_amount: 0
  };
  const [form] = Form.useForm();
  const [EditNameModalVisible, setEditNameModalVisible] = useState(false);
  const [editedName, setEditedName] = useState('');


  const showModalEditName = () => {
    console.log('showModalEditName');
    setEditNameModalVisible(true);
  }

  const handleSubmitEditName = async (values: any) => {
    console.log('here is the value:',values);
    const newName = values.newUsername;
    const result = await dispatch(changeUsername(values));
    if (result.payload.error) {
      alert(result.payload.error);
    } else {
      setEditedName(newName);
      setEditNameModalVisible(false);
      alert(result.payload.data);
    }
    
  }

  const handleCancelEditName = () => {
    setEditNameModalVisible(false)
    form.resetFields()
  };


  useEffect(() => {
    errorMessage && notification.error({message: errorMessage})
    dispatch(setUserFormErrorMessage(undefined));
  }, [errorMessage]);


  const UnLinkWallet = async () => {
    confirm({
      title: 'Do you Want to unlink ' + userInfo.wallet + '?',
      content: '',
      async onOk() {
        deactivate?.();
        //setLoading(true);
        let rel = await CommonHelper.postJSON(API_User_UnLinkWallet, {});
        if (rel) {
          if (!rel.error) {
            ///userInfo.wallet = '';
            setUpdateNow((updateNow) => (updateNow > 100 ? 0 : ++updateNow));
            dispatch(loginUser({provider: 'Token'}));
          } else notification.error({message: rel.error});
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };


  const tabItems: { label: string, key: string, children: React.ReactNode }[] = [
    {
      label: 'Account info', key: 'account-info', children:
        <Row gutter={[10, 10]}>
          <Col md={6} sm={24} xs={24}><OmsText textType={'label'}>Username</OmsText></Col>
          <Col md={18} sm={24} xs={24}><OmsText
            textType={'text-value'}>{editedName ? editedName : userInfo.username}<Link
            onClick={showModalEditName} style={{marginLeft: '5px'}}>Edit</Link></OmsText></Col>
          <Col md={6} sm={24} xs={24}><OmsText textType={'label'}>Points</OmsText></Col>
          <Col md={18} sm={24} xs={24}><OmsText textType={'text-value'}>{userInfo.points} (invite friends to earn
            points)</OmsText></Col>
          <Col md={6} sm={24} xs={24}><OmsText textType={'label'}>Mining level</OmsText></Col>
          <Col md={18} sm={24} xs={24}><OmsText
            textType={'text-value'}>{tier._id} {tier._id == 3 ? '(max)' : tier._id == 2 ? '(Next level at 100 points)' : tier._id == 1 ? '(Next level at 10 points)' : '(Please verify email to get Tier 1)'}</OmsText></Col>
          <Col md={6} sm={24} xs={24}><OmsText textType={'label'}>Email</OmsText></Col>
          <Col md={18} sm={24} xs={24}><OmsText textType={'text-value'}>{userInfo.email}</OmsText></Col>
          <Col md={6} sm={24} xs={24}><OmsText textType={'label'}>Wallet</OmsText></Col>
          <Col md={18} sm={24} xs={24}><OmsText textType={'text-value'}>{userInfo.wallet}{!userInfo.wallet ? <>
            <WalletConnectLogin type={'link'}
                                onSuccess={(flatSig) => {
                                  dispatch(linkWallet({sig: flatSig, walletType: 'walletconnect'})).then((res: any) => {
                                    dispatch(loginUser({provider: 'Token'}));
                                  });
                                }}></WalletConnectLogin>
            <WalletInjectLogin type={'link'} onSuccess={(flatSig) => {
              dispatch(linkWallet({sig: flatSig, walletType: 'injected'})).then((res: any) => {
                dispatch(loginUser({provider: 'Token'}));
              });
            }}></WalletInjectLogin>
          </> : <Link
            onClick={() => UnLinkWallet()}>UnLink</Link>}</OmsText></Col>
          <Col md={6} sm={24} xs={24}></Col>
          <Col md={18} sm={24} xs={24}><Button ghost danger onClick={() => {
            navigate('/changePassword');
          }
          }>Change password</Button></Col>
        </Row>
    },
    {
      label: 'Referral info', key: 'Referral-Info', children: <Row gutter={[10, 10]}>
        <Col md={6} sm={24}><OmsText textAlign={'right'} textType={'label'}>Your referral code</OmsText></Col>
        <Col md={18} sm={24} style={{textAlign: 'left'}}>
          <OmsInput value={String(RefID)} suffix={<CopyToClipboard text={String(RefID)} onCopy={() => {
            setRefCodeCopied(true);
            setTimeout(() => setRefCodeCopied(false), 1000);
            notification.success({message: 'Referral code copied!'})
          }}>{refCodeCopyIcon}</CopyToClipboard>} readOnly={true}/></Col>
        <Col md={6} sm={24}><OmsText textAlign={'right'} textType={'label'}>Ref Link</OmsText></Col>
        <Col md={18} sm={24} style={{textAlign: 'left'}}> <OmsInput
          value={`${baseUrl}/login?refID=${RefID}`}
          suffix={<CopyToClipboard
            text={String(`${baseUrl}/login?refID=${RefID}&utm_source=Referral`)}
            onCopy={() => {
              setRefLinkCopied(true);
              setTimeout(() => setRefLinkCopied(false), 1000)
              notification.success({message: 'Referral Link copied!'})
            }}>{refCodeLinkIcon}</CopyToClipboard>}
          readOnly={true}/></Col>
        <Col md={6} sm={0} xs={0}></Col>
        <Col md={18} sm={24} xs={24} className={'social-share'}>
          <TelegramShareButton url={`${baseUrl}/login?refID=${RefID}`}>
            <TelegramIcon size={32}/>
          </TelegramShareButton>
          <TwitterShareButton url={`${baseUrl}/login?refID=${RefID}`}>
            <TwitterIcon size={32}/>
          </TwitterShareButton>
          <FacebookShareButton url={`${baseUrl}/login?refID=${RefID}`}>
            <FacebookIcon size={32}/>
          </FacebookShareButton>
        </Col>
      </Row>
    }, {
      label: 'Social', key: 'social', children: <Row>
        <Col md={24} sm={24}><SocialButton provider={'facebook'} appId={'1492047941245981'} labelId={'Facebook ID'}
                                           onLoginSuccess={(res) => {
                                             console.log(res);
                                             dispatch(connectSocial({
                                               provider: 'Facebook',
                                               accessToken: res._token.accessToken
                                             }));
                                           }
                                           }
                                           connectedId={userInfo.facebookId}
                                           icon={<FacebookOutlined/>} size={'large'}
                                           onLoginFailure={(res) => console.log(res)}>Connect with Facebook
        </SocialButton></Col>
      </Row>
    }
  ]
  return <CenterContainer className={'account-info-container'} isVisible={true} width={700}>
    <div className={'header'}>
      <Title level={1}>ACCOUNT INFO</Title>
      <Button type={'primary'} danger ghost className={'logout-btn'} onClick={() => dispatch(logout())}>Sign
        out</Button>
    </div>
    <Tabs defaultValue={'Account-Info'} items={tabItems}/>
    <OmsModal modalName={'edit-user-name'} open={EditNameModalVisible}
              centered={true}
              footer={null} closable={true} className={'change-username-modal'}>
      <Form form={form} onFinish={handleSubmitEditName} style={{background: 'none'}}>
        <div className={'header-wrapper'}>
          <Title level={2}>{'Change username'}</Title>
        </div>
        <div className={'body-wrapper'}>
          <FormItem name={'newUsername'} label="Username">
            <Input value={userInfo.username}/>
          </FormItem>
        </div>
        <div className={'footer-wrapper'}>
          <Button type={'primary'} htmlType={'submit'}>Save</Button>
          <Button type={'primary'} danger ghost onClick={() => {
            setEditNameModalVisible(false)
            form.resetFields()
          }}>Cancel</Button>
        </div>
      </Form>
    </OmsModal>
  </CenterContainer>
}

export default AccountPage;
