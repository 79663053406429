import { Skeleton } from 'antd';
import Blockies from 'react-blockies';
import { useWeb3React } from '@web3-react/core';

/**
 * Shows a blockie image for the provided wallet address
 * @param {*} props
 * @returns <Blockies> JSX Elemenet
 */

function Blockie(props:any) {
  const { account,isActive } = useWeb3React();
  if (!props.address && (!account || !isActive))
    return <Skeleton.Avatar active size={40} />;

  return (
    <Blockies
      seed={
        props.currentWallet
          ? account?.toLowerCase()
          : props.address.toLowerCase()
      }
      className="identicon"
      {...props}
    />
  );
}

export default Blockie;
