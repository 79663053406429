import { Button, Card, Image, Modal, notification, Typography } from 'antd';
import { EllipsisOutlined, LockOutlined, SendOutlined, UnlockOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { loadNFT, loadNFTs, updateModalShow, updateNFTStatus } from './NFTBalanceSlice';
import { useNavigate } from 'react-router-dom';
import { CardGridProps } from 'antd/lib/card/Grid';
import OmsModal from '../CustomComponent/OmsModal';
import { API_NFT_LOCK, API_NFT_UNLOCK, API_NFT_UPDATEBLOCK,ActiveNetworks } from 'helpers/config';
import { userInfoSelector } from '../UserForm/UseSlice';
import AddressInput from 'components/AddressInput';
import { useOmsWeb3 } from '../../hooks/UseOmsWeb3';
import Pubsub from 'pubsub-js';
import { usePostJson } from '../../hooks/UsePostJson';
import { loadCharacterInfoSelector } from 'components/SchoolModule/CharacterSlide';
import { loadAllModules, loadAllModulesSelector } from 'components/SchoolModule/SchoolModuleSlice';
import { useWeb3React } from '@web3-react/core';
import { activeWithConnectorId, switchNetwork, URLS } from 'helpers/chains';
import { useParams } from 'react-router';
import { ethers } from 'ethers';


const { Title } = Typography;

interface NFTCardProps extends CardGridProps {
  nft: any;
  meta: any
}

const gridStyle: React.CSSProperties = {
  width: '33%',
  textAlign: 'center',
};

const NFTCard = (_props: NFTCardProps) => {
  const { meta, nft, ...props } = _props;
  const [pending, setPending] = useState(false);
  const postJson = usePostJson();
  const {
    transfer,
    unlock,
    lock,
    checkLock,
    ownerOf
  } = useOmsWeb3();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showInstallMetamask, setShowInstallMetamask] = useState(false);
  const userInfo = useAppSelector(userInfoSelector);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [receiver, setReceiver] = useState('');
  const userCharacterInfo = useAppSelector(loadCharacterInfoSelector);
  const moduleInfo = useAppSelector(loadAllModulesSelector);
  const [checkMining, setCheckMining] = useState(false);
  //react web3
  useEffect(() => {
    const timer = setTimeout(() => {
      // Call the main_provider function here
      const main_provider = new ethers.providers.JsonRpcProvider(
        URLS[ActiveNetworks.polygon][0]
      );
    }, 1000); // Delay of 1 second

    return () => {
      clearTimeout(timer); // Clear the timer when the component unmounts
    };
  }, []); // Empty dependency array to run the effect only once

  const { chainId, account, provider, isActive, connector } = useWeb3React();
  const [item,setItem] = useState<any>(null);
  // const {id} = useParams();

  function checkOwnerOf(blockNumber: string, tokenId: number, address: string, onSuccess: () => void) {
    console.log('Checking owner of');
    let retryCount = 0;
    let loading = false;
    const intervalId = setInterval(async () => {
      if (loading) return;
      loading = true;
      const result: string = await ownerOf(tokenId);
      if (result?.toLowerCase() === address?.toLowerCase()) {
        console.log('Block process successfully.');
        clearInterval(intervalId);
        setTimeout(() => {
          fetch(API_NFT_UPDATEBLOCK, {
            method: 'POST',
            body: JSON.stringify({ 'startBlock': blockNumber }),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            mode: 'cors'
          }).then(() => onSuccess());
        }, 5000);
      } else if (retryCount >= 9) {
        console.log('Block till pending');
        clearInterval(intervalId);
      } else {
        retryCount++;
      }
      loading = false;
    }, 5000);
  }

  function checkingLockStatus(blockNumber: string, tokenId: number, suggestLockStatus: boolean, onSucces: () => void) {
    console.log('Checking lock status');
    let retryCount = 0;
    let loading = false;
    const intervalId = setInterval(async () => {
      if (loading) return;
      loading = true;
      const result = !(await checkLock(tokenId));
      if (result === suggestLockStatus) {
        console.log('Block process successfully.');
        clearInterval(intervalId);
        setTimeout(() => {
          postJson(API_NFT_UPDATEBLOCK, { 'startBlock': blockNumber }).then(() => {
            setPending(false);
            onSucces()
          });
        }, 5000);
      } else if (retryCount >= 9) {
        console.log('Block till pending');
        clearInterval(intervalId);
      } else {
        retryCount++;
      }
      loading = false;
    }, 5000);
  }

  const handleTransferClick = () => {
    setShowTransferModal(true);
  };
  const handleClick = () => {
    showModal();
  };
  const showModal = () => {
    setIsModalOpen(true);
    dispatch(updateModalShow(true));
  };

  const transferNFT = async () => {
    if (!window.ethereum){
      setShowInstallMetamask(true);
      return;
    }
    if (!isActive || !account) {
      activeWithConnectorId('injected', 0);
      return
    }
    if (chainId != ActiveNetworks.polygon) {
      notification.info({
        message: 'Please switch network to Polygon',
      });
      await switchNetwork(connector, ActiveNetworks.polygon);
      return;
    }
    if(!provider)
    {
      notification.info({
        message: 'Please connect your wallet',
      });
      return;
    }
    if(!userInfo.wallet) {
      notification.info({
        message: 'Please link your wallet',
      });
      return;
    }
    if (account.toLowerCase() != userInfo.wallet.toLowerCase()) {
      notification.info({
        message: 'Please change your wallet to ' + userInfo.wallet,
      });
      return;
    }
    setPending(true);
    const topic = transfer({ receiver, token_id: nft?.token_id });
    setShowTransferModal(false);
    Pubsub.subscribe(topic, (msg: any, res: any) => {
      if (res?.data) {
        const tx = res.data;
        notification.success({
          message: 'Your nft is being transferred! Tx: ' + tx.transactionHash,
        });
        dispatch(updateNFTStatus({
          token_id: nft.token_id,
          nftStatus: 'pending',
          wallet: userInfo.wallet || '',
          signature: JSON.stringify(tx)
        }));
        checkOwnerOf(tx?.blockNumber, nft.token_id, receiver, () => {
          if (!userInfo.wallet) throw new Error('Wallet is not set');
          dispatch(loadNFTs(userInfo.wallet));
        });
        postJson(API_NFT_UPDATEBLOCK, { 'startBlock': res?.data?.blockNumber }).then((result) => {
          console.log(result);
        }).finally(() => {
          setPending(false);
        });
      }
      if (res?.error) {
        console.log(res?.error);
        notification.error({
          message: 'Failed! Please try again',
        });
        setPending(false);
      }
    });
  }

  const checkNFTNotInMining = (nftId: number): boolean => {
    setCheckMining(true);
    if (userCharacterInfo) {
      const charInfo = userCharacterInfo.find(char => char.nft_id === nftId);
      if (charInfo?.mining_module && moduleInfo) {
        const moduleName = moduleInfo.find(module => module.id === charInfo.mining_module)?.name;
        notification.info({message: `Character is working at ${moduleName}, please "Claim" character to unlock`});
        setCheckMining(false);
        return false;
      }
    }
    setCheckMining(false);
    return true;
  }


  const unlockNFT = async () => {
      if (!window.ethereum){
        setShowInstallMetamask(true);
        return;
      }
      if (!isActive || !account) {
        activeWithConnectorId('injected', 0);
        return
      }
      if (chainId != ActiveNetworks.polygon) {
        notification.info({
          message: 'Please switch network to Polygon',
        });
        await switchNetwork(connector, ActiveNetworks.polygon);
        return;
      }
      if(!provider)
      {
        notification.info({
          message: 'Please connect your wallet',
        });
        return;
      }
      if(!userInfo.wallet) {
        notification.info({
          message: 'Please link your wallet',
        });
        return;
      }
      if (account.toLowerCase() != userInfo.wallet.toLowerCase()) {
        notification.info({
          message: 'Please change your wallet to ' + userInfo.wallet,
        });
        return;
      }
    const isNotInMining = checkNFTNotInMining(nft._id);
    if (isNotInMining) {
      setPending(true);
      const rel = await postJson(API_NFT_UNLOCK, {
        tokenId: nft.token_id,
      });
      if (!rel || !rel.signId) {
        notification.error({
          message: rel.error || 'Failed! Please try again',
        });
        return;
      }
      const topic = unlock({ rel, nft });

      setIsModalOpen(false);
      console.log(`subscribing to topic: ${topic}`);
      Pubsub.subscribe(topic, (msg: any, res: any) => {
        if (res?.data) {
          const tx = res.data;
          dispatch(updateNFTStatus({
            token_id: nft.token_id,
            nftStatus: 'pending',
            wallet: userInfo.wallet || '',
            signature: JSON.stringify(tx)
          }));
          notification.success({
            message: 'Your nft is being unlocked! Tx: ' + tx.transactionHash,
          });
          console.log('tx:', tx);
          // check block status every 5 seconds, max 5 times
          checkingLockStatus(tx?.blockNumber, nft.token_id, false, () => {
            if (!userInfo.wallet) throw new Error('Wallet is not set');
            dispatch(loadNFT({ wallet: userInfo.wallet, token_id: nft.token_id }));
          })
        }
        if (res?.error) {
          console.log(res?.error);
          notification.error({
            message: 'Failed! Please try again',
          });
          setPending(false)
        }
      });
    } 
  }
  const lockNFT = async () => {
    if (!window.ethereum){
      setShowInstallMetamask(true);
      return;
    }
    if (!isActive || !account) {
      activeWithConnectorId('injected', 0);
      return
    }
    if (chainId != ActiveNetworks.polygon) {
      notification.info({
        message: 'Please switch network to Polygon',
      });
      await switchNetwork(connector, ActiveNetworks.polygon);
      return;
    }
    if(!provider)
    {
      notification.info({
        message: 'Please connect your wallet',
      });
      return;
    }
    if(!userInfo.wallet) {
      notification.info({
        message: 'Please link your wallet',
      });
      return;
    }
    if (account.toLowerCase() != userInfo.wallet.toLowerCase()) {
      notification.info({
        message: 'Please change your wallet to ' + userInfo.wallet,
      });
      return;
    }
    setPending(true);
    const rel = await postJson(API_NFT_LOCK, {
      tokenId: nft.token_id,
    });
    if (!rel || !rel.signId) {
      notification.error({
        message: rel.error || 'Failed! Please try again',
      });
      return;
    }
    const topic = lock({ rel, nft });
    setIsModalOpen(false);
    Pubsub.subscribe(topic, (msg: any, res: any) => {
      if (res?.data) {
        const tx = res.data;
        dispatch(updateNFTStatus({
          token_id: nft.token_id,
          nftStatus: 'pending',
          wallet: userInfo.wallet || '',
          signature: JSON.stringify(tx)
        }));

        notification.success({
          message: 'Your nft is being lock! Tx: ' + tx.transactionHash,
        });
        console.log('tx:', tx);

        checkingLockStatus(tx?.blockNumber, nft.token_id, true, () => {
          if (!userInfo.wallet) throw new Error('Wallet is not set');
          dispatch(loadNFT({ wallet: userInfo.wallet, token_id: nft.token_id }));
        });
      }
      if (res?.error) {
        console.log(res?.error);
        notification.error({
          message: 'Failed! Please try again',
        });
        setPending(false);
      }
    });
  }
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(updateModalShow(false));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(updateModalShow(false));
  };
  const nftThumbnail = meta.image.replace('png','thumb.jpg');
  return <Card.Grid className={'nft-card-item'} {...props} key={nft.token_id}>
    {meta && <>
      <Image src={nftThumbnail} preview={false} alt={meta.name} onClick={() => navigate('/nfts/gmd/' + meta.edition)} />
      <Title level={4}>{nft.lock == 1 && <LockOutlined />} {meta.name}</Title>
      <div className={'nft-card-item-buttons'}>
        {(pending || nft.nftStatus === 'pending') && <Button
          icon={<EllipsisOutlined />} type={'default'} ghost size={'middle'}>Pending</Button> || <>
            {!nft.nftStatus && <Button
              icon={nft.lock == 1 ? <UnlockOutlined /> : <LockOutlined />}
              type={'default'} ghost
              onClick={() => handleClick()} size={'middle'}>
              {nft.lock == 1 ? 'Unlock' : 'Lock'}
            </Button>}
            {nft.lock == 0 && !nft.nftStatus && <Button
              icon={<SendOutlined />}
              type={'default'} ghost
              onClick={() => handleTransferClick()} size={'middle'}>
              Transfer
            </Button>}
          </>}
      </div>

      <OmsModal modalName={'home-phase-coming-soon'} open={isModalOpen} footer={null} closable={false}
        className={'coming-soon-modal'}>
        <div className={'header-wrapper'}>
          <Title level={2}>{nft.lock == 1 ? 'Unlock' : 'Lock'} {meta.name}</Title>
        </div>
        <div className={'body-wrapper'}>
          <span>{nft.lock == 1 ? 'After unlock you can not use this nft in game' : 'After lock you can use this nft in game'}</span>
        </div>
        <div className={'footer-wrapper'}>
          <Button type={'dashed'} size={'large'} onClick={() => {
            setIsModalOpen(false);
          }}>Cancel</Button>
          <Button type={'primary'} size={'large'} style={{ marginLeft: 5 }} onClick={() => {
            nft.lock == 1 ? unlockNFT() : lockNFT();
          }}>{nft.lock == 1 ? 'Unlock' : 'Lock'}</Button>
        </div>
      </OmsModal>
      <OmsModal modalName={'home-phase-coming-soon'} open={showTransferModal} footer={null} closable={false}
        className={'coming-soon-modal'}>
        <div className={'header-wrapper'}>
          <Title level={2}>Transfer {meta.name}</Title>
        </div>
        <AddressInput
          autoFocus
          placeholder="Receiver"
          onChange={setReceiver}
        />
        <div className={'footer-wrapper'}>
          <Button type={'dashed'} size={'large'} onClick={() => {
            setShowTransferModal(false);
          }}>Cancel</Button>
          <Button type={'primary'} size={'large'} style={{ marginLeft: 5 }} onClick={() => {
            transferNFT();
          }}>Transfer Now</Button>
        </div>
      </OmsModal>
      <Modal
        title={'Install Metamask'}
        visible={showInstallMetamask}
        onCancel={() => {
          setShowInstallMetamask(false);
        }}
        footer={null}
        closable={true}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'Roboto',
          }}
        >
          <Typography.Title level={4} style={{ textAlign: 'center' }}>
            {'Please install Metamask'}
            <br />{' '}
            <a target="_blank" href="https://metamask.io/download/" rel="noreferrer">
              https://metamask.io/download/
            </a>
          </Typography.Title>
        </div>
      </Modal>
    </>}
  </Card.Grid>
}
export default NFTCard;
