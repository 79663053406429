import * as React from 'react';
import { useSelector } from 'react-redux';
import { Step, stepSelector } from './UseSlice';
import './LoginFormContainer.scss';
import { Animated } from 'react-animated-css';


interface LoginFormContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  step: Step
}

const LoginFormContainer = (props: LoginFormContainerProps) => {
  const step = useSelector(stepSelector);
  return <Animated {...props} animationOut={'fadeOut'} animationIn={'fadeIn'} isVisible={step === props.step}/>;
}
export default LoginFormContainer;