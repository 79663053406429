import { useAppSelector } from 'app/hooks';
import { loadUserGameInfoSelector } from './SchoolModule/gameUserInfoSlide';
import { useSelector } from 'react-redux';
import { stepSelector } from './UserForm/UseSlice';

const styles = {
  root: {
    position: 'absolute',
    top: '12rem',
    right: '2rem',
    zIndex: 20,
    width: 200,
  },
} as const;

function ResourceShow() {
  const userGameInfo = useAppSelector(loadUserGameInfoSelector);
  const stepLogin = useSelector(stepSelector);

  let listResource: any[] = [];

  if (userGameInfo.resources) {
    const keys = Object.keys(userGameInfo.resources);
    listResource = keys.map(resource => {
      return <div key={resource} style={{ color: 'white', textAlign: 'center' }}>{resource}: {userGameInfo.resources[resource]}</div>
    })
  }

  return (
    <>
      {userGameInfo && <div
        style={styles.root}
      >
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
          <div style={{ backgroundColor: 'rgba(0,0,0,0.6)', width: '100%', padding: 10, borderRadius: 10 }}>
            {listResource}
          </div>
        </div>
      </div >}
    </>
  );
}

export default ResourceShow;
