import {Button, Typography} from 'antd';
import React from 'react';

import Account from 'components/Account/Account';
import {useWeb3React} from '@web3-react/core';
import NFTBalance from 'components/NFTBalance/NFTBalance';
import {commonStyle} from '../helpers/styles';

const { Text } = Typography;

const styles = {
  title: {
    fontSize: '20px',
    fontWeight: '700',
  },
  text: {
    fontSize: '16px',
  },
  card: {
    ...commonStyle.card,
  },
  input: {
    padding: '0',
    fontWeight: '500',
    fontSize: '23px',
    display: 'block',
    width: '100%',
  },
  priceSwap: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '15px',
    color: '#434343',
    marginTop: '8px',
    padding: '0 10px',
  },
  timeline: {
    marginBottom: '-45px',
  },
};

export default function Home() {
  const { isActive } = useWeb3React();

  if (true) {
    return <NFTBalance />;
  }
  return (
    <div
      style={{
        maxWidth: 640,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '2px',
        minHeight: '60vh',
      }}
    >
      <Button type="primary" size="large" color="white" className="menu-button">
        <Account />
      </Button>
    </div>
  );
}
