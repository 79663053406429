import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import { abiList, ActiveNetworks, addressList, API_NFT_GETNFTS, API_NFT_UDPATE_STATUS } from '../../helpers/config';
import { URLS } from '../../helpers/chains';
import { RootState } from '../../app/store';

interface NFTBalanceState {
  NFTsLoaded: boolean
  status: 'loading' | 'ready';
  NFTs?: any[]
  modalShow: boolean
}

type NftStatus = 'pending' | undefined;

interface NFTStatus {
  token_id: number;
  nftStatus: NftStatus;
  wallet: string;
  signature: string;
}

const initialState: NFTBalanceState = {
  status: 'ready',
  NFTsLoaded: false,
  modalShow: false
}

const nftBalanceSlice = createSlice({
  name: 'nft',
  initialState: initialState,
  reducers: {
    updateModalShow: (state, action: PayloadAction<boolean>) => {
      state.modalShow = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadNFTs.pending, (state, action) => {
      state.status = 'loading';
    }).addCase(loadNFTs.fulfilled, (state, action) => {
      const NFTs: any[] = action.payload.data || [];
     // console.log(NFTs);
      state.NFTs = NFTs.map(nft => {
        return {
          // token_id: nft?.toNumber(),
          chainId: ActiveNetworks.polygon,
          token_address: addressList.polyNFTAddress.toLowerCase(),
          ...nft,
          token_id: nft._id,
        }
      })
      state.status = 'ready';
    }).addCase(loadNFTs.rejected, (state, action) => {
      state.status = 'ready';
    }).addCase(loadNFT.pending, (state, action) => {
      state.status = 'loading';
    }).addCase(loadNFT.fulfilled, (state, action) => {
      const nft: any = action.payload.data?.[0];
      console.log(nft);
      // find the nft in state.NFTs and update it
      const nftUpdate = state.NFTs?.find(nft => nft.token_id === action.meta.arg.token_id);
      if (nftUpdate) {
        Object.assign(nftUpdate, nft);
        nftUpdate.nftStatus = nft.nftStatus;
      }
      console.log(nftUpdate);
      state.status = 'ready';
    }).addCase(loadNFT.rejected, (state, action) => {
      state.status = 'ready';
    }).addCase(updateNFTStatus.pending, (state, action) => {
    }).addCase(updateNFTStatus.fulfilled, (state, action) => {
      console.log('updateNFTStatus::' + action.payload);
      if (state.NFTs) {
        state.NFTs.find(nft => nft.token_id === action.meta.arg.token_id).nftStatus = action.meta.arg.nftStatus;
      }
    })
  }
});

const main_provider = new ethers.providers.JsonRpcProvider(
  URLS[ActiveNetworks.polygon][0]
);

const mainNFTContract = new ethers.Contract(
  addressList.polyNFTAddress.toLowerCase(),
  abiList.abiNFT,
  main_provider
);

export const loadNFTs = createAsyncThunk('nft/loadlist', async (wallet: string) => {
  console.log('nft/load::' + wallet);
  // return await mainNFTContract.walletOfOwner(wallet);
  return await fetch(API_NFT_GETNFTS, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({wallet: wallet}),
    credentials: 'include',
    mode: 'cors'
  }).then(response => response.json())
})

export const loadNFT = createAsyncThunk('nft/load', async (payload: { wallet: string, token_id: number }) => {
  console.log('nft/load::', payload);
  // return await mainNFTContract.walletOfOwner(wallet);
  return await fetch(API_NFT_GETNFTS, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload),
    credentials: 'include',
    mode: 'cors'
  }).then(response => response.json())
})

export const updateNFTStatus = createAsyncThunk('nft/updateStatus', async (nft: NFTStatus) => {
  console.log('nft/update::' + nft);
  return await fetch(API_NFT_UDPATE_STATUS, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(nft),
    credentials: 'include',
    mode: 'cors'
  }).then(response => response.json())
})

export default nftBalanceSlice.reducer;
export const {updateModalShow} = nftBalanceSlice.actions;
export const nftsSelector = (state: RootState) => state.nftBalance.NFTs;
export const nftStatusSelector = (state: RootState) => state.nftBalance.status;
export const nftModalShowSelector = (state: RootState) => state.nftBalance.modalShow;
