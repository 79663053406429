/* eslint-disable react/react-in-jsx-scope */
import { useParams } from 'react-router';
import CenterContainer from '../Layout/CenterContainer';
import { useAppSelector } from '../../app/hooks';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Image,Modal,notification,Row,Typography } from 'antd';
import {
  userInfoSelector,

} from '../UserForm/UseSlice';
import * as CommonHelper from 'helpers/common';
import AnimatedContainer from 'components/Layout/AnimatedContainer';
import {
  abiList,
  ActiveNetworks,
  API_NFT_GETMETADATA,
  API_NFT_GETOWNER,
  API_NFT_LOCK,
  API_NFT_UNLOCK,
  NFTContractList
} from 'helpers/config';
import './NFTCharacter.scss';
import { ethers } from 'ethers';
import { activeWithConnectorId, switchNetwork, URLS } from 'helpers/chains';
import { useWeb3React } from '@web3-react/core';
import { LockOutlined, SendOutlined, UnlockOutlined } from '@ant-design/icons';
import OmsModal from 'components/CustomComponent/OmsModal';
import AddressInput from 'components/AddressInput';
interface NFTCharacterProps {

}
const gridStyle: React.CSSProperties = {
  width: '33%',
  textAlign: 'center',
};
const {Title} = Typography;
const NFTCharacter = (props: NFTCharacterProps) => {
  const main_provider = new ethers.providers.JsonRpcProvider(
    URLS[ActiveNetworks.polygon][0]
  );
  const { chainId, account, provider, isActive, connector } = useWeb3React();
  const userInfo = useAppSelector(userInfoSelector);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingNFT, setLoadingNFT] = useState(false);
  const [showInstallMetamask, setShowInstallMetamask] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [receiver, setReceiver] = useState('');
  const [item,setItem] = useState<any>(null);
  const {id} = useParams();
  useEffect(() => {
    loadData();
  }
  ,[]);
  const handleTransferClick = () => {
    setShowTransferModal(true);
  };
  const handleClick = () => {
    showModal();
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  // write function to load NFT meta from api
  const loadData = async () => {
    setLoadingNFT(true);
    try {
      // const nftContract = new ethers.Contract(
      //   NFTContractList[ActiveNetworks.polygon],
      //   abiList.abiNFT,
      //   main_provider
      // );
      // const tx = await nftContract.totalSupply();
      //console.log(tx);
      // if (tx) {
      //   setData((info) => ({ ...info, totalSupply: Number(tx) }));
      // }
      // optionsStaking.function_name = "tTokenReward";
      // const tx2 = await nftContract.maxSupply();
      // //console.log("Reward",tx2);
      // if (tx2) {
      //   setData((info) => ({
      //     ...info,
      //     maxSupply: Number(tx2),
      //   }));
      // }
      //optionsStaking.function_name = "tStakingNFTs";
      // const tx3 = await nftContract.cost();
      // console.log('cost',tx3);
      // if (tx3) {
      //   setData((info) => ({
      //     ...info,
      //     price: Number(ethers.utils.formatEther(tx3)),
      //   }));
      // }

      // set price to state
      let id_str:any = id;
      let rel = await CommonHelper.postJSON(API_NFT_GETMETADATA, { ids:[parseInt(id_str)] });
      // set price to state
      console.log( rel.data[0])
      if (rel && rel.data) {
        let rel2 = await CommonHelper.postJSON(API_NFT_GETOWNER, { id:parseInt(id_str) });
        if(rel2&&rel2.data){
          setItem({...rel.data[0],owner:rel2.data});
        }
      }


    } catch (e) {
      console.log(e);
      // notification.error({
      //   message: e.message,
      // });
    }
    setLoadingNFT(false);
  };
  const transferNFT = async () => {
    if (!window.ethereum){
      setShowInstallMetamask(true);
      return;
    }
    if (!isActive || !account) {
      activeWithConnectorId('injected', 0);
      return
    }
    if (chainId != ActiveNetworks.polygon) {
      notification.info({
        message: 'Please switch network to Polygon',
      });
      await switchNetwork(connector, ActiveNetworks.polygon);
      return;
    }
    if(!provider)
    {
      notification.info({
        message: 'Please connect your wallet',
      });
      return;
    }
    if(!userInfo.wallet) {
      notification.info({
        message: 'Please link your wallet',
      });
      return;
    }
    if (account.toLowerCase() != userInfo.wallet.toLowerCase()) {
      notification.info({
        message: 'Please change your wallet to ' + userInfo.wallet,
      });
      return;
    }
    if(ethers.utils.isAddress(receiver) == false) {
      notification.info({
        message: 'Please input a valid address',
      });
      return;
    }
    setLoading(true);
    try {
      let provider_meta:any = provider
      const signer = provider_meta.getSigner();
      let network:string = ActiveNetworks.polygon.toString();
      let contractAddress:any = NFTContractList[network as keyof typeof NFTContractList];
      const nftContract = new ethers.Contract(
        contractAddress,
        abiList.abiNFT,
        main_provider
      );
      const tx = await nftContract
        .connect(signer)
        .transferFrom(account, receiver, item?._id);
      tx.wait();
      notification.success({
        message: 'Your nft is being transferred! Tx: ' + tx.hash,
      });
    } catch (e) {
      console.log(e);
      notification.error({
        message: 'Failed! Please try again',
      });
    }
    setLoading(false);

  }
  const unlockNFT = async () => {
    if (!window.ethereum){
      setShowInstallMetamask(true);
      return;
    }
    if (!isActive || !account) {
      activeWithConnectorId('injected', 0);
      return
    }
    if (chainId != ActiveNetworks.polygon) {
      notification.info({
        message: 'Please switch network to Polygon',
      });
      await switchNetwork(connector, ActiveNetworks.polygon);
      return;
    }
    if(!provider)
    {
      notification.info({
        message: 'Please connect your wallet',
      });
      return;
    }
    if(!userInfo.wallet) {
      notification.info({
        message: 'Please link your wallet',
      });
      return;
    }
    if (account.toLowerCase() != userInfo.wallet.toLowerCase()) {
      notification.info({
        message: 'Please change your wallet to ' + userInfo.wallet,
      });
      return;
    }
    setLoading(true);
    try {
      const rel = await CommonHelper.postJSON(API_NFT_UNLOCK, {
        tokenId: item._id,
      });
      if (!rel || !rel.signId) {
        notification.error({
          message: rel.error || 'Failed! Please try again',
        });
        setLoading(false);
        return;
      }
      let provider_meta:any = provider
      const signer = provider_meta.getSigner();
      let network:string = ActiveNetworks.polygon.toString();
      let contractAddress:any = NFTContractList[network as keyof typeof NFTContractList];
      const nftContract = new ethers.Contract(
        contractAddress,
        abiList.abiNFT,
        main_provider
      );
      let sig = ethers.utils.splitSignature(rel.signature);
      //console.log(rel);
      const tx = await nftContract.connect(signer).UnlockNFT(item._id, rel.signId, sig.v, sig.r, sig.s);
      //setLoading(true);
      tx.wait();
      notification.success({
        message: 'Your nft is being unlocked! Tx: ' + tx.hash,
      });

    }
    catch (e) {
      console.log(e);
      notification.error({
        message: 'Failed! Please try again',
      });
    }
    setLoading(false);
  }
  const lockNFT = async () => {
    if (!window.ethereum){
      setShowInstallMetamask(true);
      return;
    }
    if (!isActive || !account) {
      activeWithConnectorId('injected', 0);
      return
    }
    if (chainId != ActiveNetworks.polygon) {
      notification.info({
        message: 'Please switch network to Polygon',
      });
      await switchNetwork(connector, ActiveNetworks.polygon);
      return;
    }
    if(!provider)
    {
      notification.info({
        message: 'Please connect your wallet',
      });
      return;
    }
    if(!userInfo.wallet) {
      notification.info({
        message: 'Please link your wallet',
      });
      return;
    }
    if (account.toLowerCase() != userInfo.wallet.toLowerCase()) {
      notification.info({
        message: 'Please change your wallet to ' + userInfo.wallet,
      });
      return;
    }
    setLoading(true);
    try {
      const rel = await CommonHelper.postJSON(API_NFT_LOCK, {
        tokenId: item._id,
      });
      if (!rel || !rel.signId) {
        notification.error({
          message: rel.error || 'Failed! Please try again',
        });
        setLoading(false);
        return;
      }
      let provider_meta:any = provider
      const signer = provider_meta.getSigner();
      let network:string = ActiveNetworks.polygon.toString();
      let contractAddress:any = NFTContractList[network as keyof typeof NFTContractList];
      const nftContract = new ethers.Contract(
        contractAddress,
        abiList.abiNFT,
        main_provider
      );
      let sig = ethers.utils.splitSignature(rel.signature);
      //console.log(rel);
      const tx = await nftContract.connect(signer).LockNFT(item._id, rel.signId, sig.v, sig.r, sig.s);
      //setLoading(true);
      tx.wait();
      notification.success({
        message: 'Your nft is being locked! Tx: ' + tx.hash,
      });

    }
    catch (e) {
      console.log(e);
      notification.error({
        message: 'Failed! Please try again',
      });
    }
    setLoading(false);
  }
  if(loadingNFT)
  {
    return <CenterContainer isVisible={true} width={1200} style={{background:'none',display:'flex',justifyContent:'center'}}>
      <Image src="/loading_shard.gif" className={'loading-mask-icon'} preview={false}></Image>
    </CenterContainer>
  }
  return (
    <CenterContainer isVisible={true} width={1200} className={'nft-character'}>
      {
        item?(<AnimatedContainer animationIn={'fadeIn'}  animationOut={'fadeOut'} isVisible={true}  style={{background:'white',padding:10}}>
          <Row gutter={8}>
            <Col xs={24} md={12}>
              <Image src={item.image} preview={false} alt={item.name}/>
            </Col>
            <Col xs={24} md={12}>
              <Row>
                <Col span={24}>
                  <Title level={3}>
                    {item.name}
                  </Title>
                  <br/>
                  <Typography.Text>
                    <b>Owned by <span style={{color:'rgb(32, 129, 226)'}}>{userInfo.wallet==item.owner.owner?'You':item.owner?.name}</span> {item.owner.block==1&&<LockOutlined/>}</b>
                  </Typography.Text>
                  {userInfo.wallet==item.owner.owner&&<div className={'nft-card-item-buttons'}>
                    <Button
                      icon={item.owner.block==1?<UnlockOutlined/>:<LockOutlined/>}
                      type={'default'} ghost
                      onClick={() => handleClick()} size={'middle'}>
                      {item.owner.block==1?'Unlock':'Lock'}
                    </Button>
                    {item.owner.block==0&&<Button
                      icon={<SendOutlined/>}
                      type={'default'} ghost
                      onClick={() => handleTransferClick()} size={'middle'}>
                      Transfer
                    </Button>}
                  </div>}
                  <Divider></Divider>
                </Col>
                <Col span={24}>
                  <Card title="Properties" bordered={false}>
                    {item && item.attributes.map(({
                      trait_type,
                      value,
                      dna
                    }: { trait_type: string, value: string, dna: string }) => {
                      return <Card.Grid style={gridStyle} key={dna}>
                        <div>
                          <div className={'card-grid-item-title'}>{trait_type}</div>
                          <div className={'card-grid-item-value'}>{value}</div>
                        </div>
                      </Card.Grid>;
                    })}
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </AnimatedContainer>):<CenterContainer isVisible={true} width={700} className={'nft-container-empty'}>
          <Title level={2}>Not Found</Title>

        </CenterContainer>}
      <Modal
        title={'Install Metamask'}
        visible={showInstallMetamask}
        onCancel={() => {
          setShowInstallMetamask(false);
        }}
        footer={null}
        closable={true}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'Roboto',
          }}
        >
          <Typography.Title level={4} style={{textAlign: 'center'}}>
            {'Please install Metamask'}
            <br/>{' '}
            <a target="_blank" href="https://metamask.io/download/" rel="noreferrer">
              https://metamask.io/download/
            </a>
          </Typography.Title>
        </div>
      </Modal>
      <OmsModal modalName={'home-phase-coming-soon'} open={isModalOpen} footer={null} closable={false}
        className={'coming-soon-modal'}>
        <div className={'header-wrapper'}>
          <Title level={2}>{item?.owner?.block==1?'Unlock':'Lock'} {item?.name}</Title>
        </div>
        <div className={'body-wrapper'}>
          <span>{item?.owner?.block==1?'After unlock you can not use this nft in game':'After lock you can use this nft in game'}</span>
        </div>
        <div className={'footer-wrapper'}>
          <Button type={'dashed'} size={'large'} onClick={() => {
            setIsModalOpen(false);
          }}>Cancel</Button>
          <Button type={'primary'} size={'large'} style={{marginLeft:5}} onClick={() => {
            item?.owner?.block==1?unlockNFT():lockNFT();
          }}>{item?.owner?.block==1?'Unlock':'Lock'}</Button>
        </div>
      </OmsModal>
      <OmsModal modalName={'home-phase-coming-soon'} open={showTransferModal} footer={null} closable={false}
        className={'coming-soon-modal'}>
        <div className={'header-wrapper'}>
          <Title level={2}>Transfer {item?.name}</Title>
        </div>
        <AddressInput
          autoFocus
          placeholder="Receiver"
          onChange={setReceiver}
        />
        <div className={'footer-wrapper'}>
          <Button type={'dashed'} size={'large'} onClick={() => {
            setShowTransferModal(false);
          }}>Cancel</Button>
          <Button type={'primary'} size={'large'} style={{marginLeft:5}} onClick={() => {
            transferNFT();
          }}>Transfer Now</Button>
        </div>
      </OmsModal>
      <OmsModal modalName={'home-phase-modal-loading'} className="modalLoading" open={loading} footer={null}
        closable={false}>
        <div>
          <div className="loadingCenter">
            <img src="/loading_shard.gif"></img>{' '}
          </div>
        </div>
      </OmsModal>
    </CenterContainer>
  )
}

export default NFTCharacter;
