import './index.scss';
import LoginForm from './LoginForm';
import UserInfoForm from './UserInfoForm';
import { changeStep, Step, stepSelector } from './UseSlice';
import RegisterForm from './SignupForm';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import EmailVerifyForm from './EmailVerifyForm';
import RegisterThanksYou from './RegisterThanksYou';
import { useEffect } from 'react';
import ResetPassword from './ResetPassword';
import UserChangeEmailForm from './UserChangeEmailForm';

interface AcclountFlowProps {
  step?: Step
}

const UserForm = (props: AcclountFlowProps) => {
  useEffect(() => {
    console.log(props);
    if (props.step){
      dispatch(changeStep(props.step));
    }
  }, [])
  const step = useAppSelector(stepSelector);
  const dispatch = useAppDispatch();
  return <div className={'account-flow-container'}>
    {step === 'login' && <LoginForm></LoginForm>}
    {step === 'user-info' && <UserInfoForm></UserInfoForm>}
    {step === 'change-email' && <UserChangeEmailForm></UserChangeEmailForm>}
    {step === 'register' && <RegisterForm></RegisterForm>}
    {step === 'verify-email' && <EmailVerifyForm></EmailVerifyForm>}
    {step === 'register-thanks-you' && <RegisterThanksYou/>}
    {step === 'reset-password' && <ResetPassword/>}
  </div>;
};

export default UserForm;
