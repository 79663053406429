import { useAppSelector } from '../../app/hooks';
import { nftsSelector } from './NFTBalanceSlice';
import NFTCard from './NFTCard';
import './NFTBalanceListView.scss';
import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { useOmsWeb3 } from '../../hooks/UseOmsWeb3';

const NFTBalanceListView = () => {
  const NFTBalances = useAppSelector(nftsSelector);
  const [nfts, setNfts] = useState<any[]>();
  const {checkNFTStatus} = useOmsWeb3();
  useEffect(() => {
    if (NFTBalances) {
      console.log('NFTBalances', NFTBalances);
      checkNFTStatus(NFTBalances.map((nft: any) => nft.token_id))
        .then((nftStatus: boolean[]) => {
          const _nfts = NFTBalances.map((nft: any, index: number) => {
            return {...nft, lock: !nftStatus[index]}
          });
          setNfts(_nfts);
        });
    }
  }, [NFTBalances]);

  return <div className={'nft-list-view'}>
    <Card bordered={false} className={'nft-cards'}>
      {!!nfts && (nfts.map((nft: any, index: number) => <NFTCard key={nft.token_id} nft={nft} meta={nft}/>))}
    </Card>
  </div>;
}

export default NFTBalanceListView;
