export const COLORS = {
  primary: '#5b83ce',
  secondary: '#55638a',
  text: '#cacaca',
};
export const commonStyle = {
  card: {
    boxShadow: `0px 0px 10px 2px ${COLORS.secondary}`,
    border: `1px solid ${COLORS.secondary}`,
    borderRadius: '12px',
    fontSize: '16px',
    fontWeight: '500',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },
};
