import {useEffect, useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {Button, notification, Statistic, Typography, Modal} from 'antd';

import {ActiveNetworks,API_WHITELIST_CLAIMSHARD, API_WHITELIST_GETSHARD, API_WHITELIST_UPDATETRANSACTION, addressList, abiList, API_WHITELIST_TRANSACTION} from 'helpers/config';
import {ethers} from 'ethers';
import {COLORS, commonStyle} from 'helpers/styles';

import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {userInfoSelector} from '../UserForm/UseSlice';
import CenterContainer from '../Layout/CenterContainer';
import './ShardWhitelist.scss';
import { appLoadingStatusSelector} from '../../app/AppConfigSlice';
import * as CommonHelper from 'helpers/common';
import {useWeb3React} from '@web3-react/core';
import {activeWithConnectorId, switchNetwork, URLS} from 'helpers/chains';
import moment from 'moment';
const {Title} = Typography;

const {Countdown} = Statistic;

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: 'Zen Kaku Gothic Antique',
    color: COLORS.text,
    flex: '1 1 0%',
    maxWidth: 1320,
    width: '100%',
    rowGap: 0,
    padding: 24,
    ...commonStyle.card,
    minHeight: '60vh',
  },
  text: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: 20,
    lineHeight: 1.2,
    color: COLORS.primary,
    fontFamily: 'Zen Kaku Gothic Antique',
    // margin:0,
  },
};

function GMDRewards() {
  const main_provider = new ethers.providers.JsonRpcProvider(
    URLS[ActiveNetworks.polygon][0]
  );
  const [showInstallMetamask, setShowIntallMetamask] = useState(false);
  const {chainId, account, provider, isActive, connector} = useWeb3React();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const userInfo = useAppSelector(userInfoSelector);
  
  const appLoading = useAppSelector(appLoadingStatusSelector);
  const [claimableRewards,setClaimableRewards] = useState(0);
  const [transactionHistory,setTransactionHistory] = useState([]);
  useEffect(() => {
    loadClaimableShard();
    loadTransactionHistory();
  }, []);
  const loadClaimableShard = async() => {
    if(!userInfo.uid) return;
    console.log('loadClaimableRewards');
    const res =await fetch(API_WHITELIST_GETSHARD, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({}),
      credentials: 'include',
      mode: 'cors'
    }).then(response => response.json());

    console.log('loadClaimableRewards', res);
    if (res?.shard_whitelist) {
      let amount = ethers.utils.parseEther(res.shard_whitelist.toString());
      console.log(amount, ethers.utils.formatEther(amount))
      setClaimableRewards(res.shard_whitelist);
    }

  };
  const loadTransactionHistory = async() => {
    if(!userInfo.uid) return;
    console.log('loadTransactionHistory');
    const res = await CommonHelper.postJSON(API_WHITELIST_TRANSACTION, {});
    if (res?.transactions) {
      setTransactionHistory(res.transactions);
    }
  }

  const claimRewards = async() => {
    if (!window.ethereum) {
      setShowIntallMetamask(true);
      return;
    }
    if (!isActive || !account) {
      activeWithConnectorId('injected', 0);
      return
    }
    if (chainId != ActiveNetworks.polygon) {
      notification.info({
        message: 'Please switch network to Polygon',
      });
      await switchNetwork(connector, ActiveNetworks.polygon);
      return;
    }
    if (account.toLowerCase() != userInfo.wallet.toLowerCase()) {
      notification.info({
        message: 'Please change your wallet to ' + userInfo.wallet,
      });
      return;
    }
    setLoading(true);
    const res = await CommonHelper.postJSON(API_WHITELIST_CLAIMSHARD, {});
    if (!res || !res.signId) {
      notification.error({
        message: res.error || 'Mint failed',
      });
      setLoading(false);
      return;
    }
    console.log('claimRewards', res);

    const signer = provider.getSigner();
    try {
      const rewardsContract = new ethers.Contract(
        addressList['ShardWhitelistAddress'],
        abiList.abiShardWhitelist,
        main_provider
      );


      let sig = ethers.utils.splitSignature(res.signature);
      // console.log((mintAmount*data.price).toString(),options);
      // return;

      //console.log(rel);
      const tx = await rewardsContract.connect(signer).claimWhitelistShard(ethers.utils.parseEther(res.amount.toString()), res.signId, sig.v, sig.r, sig.s);;
      //setLoading(true);

      notification.success({
        message: 'Congratulations! You claimed your SHARD! Tx: ' + tx.hash,
      });
      await CommonHelper.postJSON(API_WHITELIST_UPDATETRANSACTION, {tx_hash: tx.hash, signId: res.signId});
      
      loadTransactionHistory();

      // tx.wait().then(function (e){
      //   console.log(e);

      //   if(e.status){
      //     //setLoading(false);
      //     setLoading(false);
      //   }
      // }

      // );
      setLoading(false);
    } catch (e) {
      //console.log(e,e.code);
      if (e.message.indexOf('user rejected transaction') >= 0) {
        notification.error({
          message: 'Error',
          description: 'Transaction rejected',
        });
      } else if (e?.data?.message?.indexOf('insufficient funds') > -1) {
        notification.error({
          message: 'Error',
          description: 'Insufficient MATIC gas fee',
        });
      } else
      notification.error({
        message: 'Error',
        description: 'Transaction failed',
      });
      // notification.error(e.message);
      setLoading(false);
    }
    
  }
  const reclaimRewards = async(item) => {
    if (!window.ethereum) {
      setShowIntallMetamask(true);
      return;
    }
    if (!isActive || !account) {
      activeWithConnectorId('injected', 0);
      return
    }
    if (chainId != ActiveNetworks.polygon) {
      notification.info({
        message: 'Please switch network to Polygon',
      });
      await switchNetwork(connector, ActiveNetworks.polygon);
      return;
    }
    if (account.toLowerCase() != userInfo.wallet.toLowerCase()) {
      notification.info({
        message: 'Please change your wallet to ' + userInfo.wallet,
      });
      return;
    }
    setLoading(true);
    
    const res = item;
    const signer = provider.getSigner();
    try {
      const rewardsContract = new ethers.Contract(
        addressList['ShardWhitelistAddress'],
        abiList.abiShardWhitelist,
        main_provider
      );


      let sig = ethers.utils.splitSignature(res.signature);
      // console.log((mintAmount*data.price).toString(),options);
      // return;

      //console.log(rel);
      const tx = await rewardsContract.connect(signer).claimWhitelistShard(ethers.utils.parseEther(res.amount.toString()), res.signId, sig.v, sig.r, sig.s);;
      //setLoading(true);

      notification.success({
        message: 'Congratulations! You claimed your SHARD! Tx: ' + tx.hash,
      });
      await CommonHelper.postJSON(API_WHITELIST_UPDATETRANSACTION, {tx_hash: tx.hash, signId: res.signId});
      
      loadTransactionHistory();


      // tx.wait().then(function (e){
      //   console.log(e);

      //   if(e.status){
      //     //setLoading(false);
      //     setLoading(false);
      //   }
      // }

      // );
      setLoading(false);
    } catch (e) {
      //console.log(e,e.code);
      if (e.message.indexOf('user rejected transaction') >= 0) {
        notification.error({
          message: 'Error',
          description: 'Transaction rejected',
        });
      } else if (e?.data?.message?.indexOf('insufficient funds') > -1) {
        notification.error({
          message: 'Error',
          description: 'Insufficient MATIC gas fee',
        });
      } else
      notification.error({
        message: 'Error',
        description: 'Transaction failed',
      });
      // notification.error(e.message);
      setLoading(false);
    }
    
  }


 
  if (!userInfo?.uid) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  return (
    <CenterContainer isVisible={true} width={1300} height={800} className={'aff-reward-container'}>
      {(appLoading != 'loading') && <div className={'aff-reward-inner-container'}>
        <Title level={2}>Welcome to Whitelist Shard</Title>
        {userInfo?.uid && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <p style={styles.text}>
              Your claimable shard: <a>{Number(claimableRewards).toFixed(1)}</a>
            </p>

         
           

            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Button type="primary" disabled={Number(claimableRewards)==0} size="large" onClick={()=>claimRewards()}>
                Claim your shard
              </Button>
            </div>
            <div className='trans-history'>
              <Title level={3} style={{color: 'white'}}>Claim History</Title>
              <div>
                {transactionHistory.map((item, index) => {
                  return (<div className='trans-history-item'>
                    <div style={{flex:0.6}}>
                    <p className='title'><a>{item.amount} SHARDS</a><br/><span className='description'>{moment(item.timestamp).format('YYYY-MM-DD HH:mm:ss')}</span></p>
                    </div>
                    <div style={{flex:0.2}}><div className='status' style={{color:item.status==2?'green':'red'}}>{item.status==2?'Completed':item.status==1?'Pending':item.status==-1?'Failed':'Created'}</div></div>
                    <div style={{flex:0.2}}>{(item.status==-1||item.status==0)&&<Button type='dashed' onClick={()=>reclaimRewards(item)}>Retry</Button>}</div>
                    </div>)
                })}
                </div>
            </div>
          </div>

        )}
        <Modal className="modalLoading" visible={loading} footer={null}>
            <div>
              <center className="loadingCenter">
                <img src="./loading_shard.gif"></img>{' '}
              </center>
            </div>
          </Modal>
          <Modal
            title={'Install Metamask'}
            visible={showInstallMetamask}
            onCancel={() => {
              setShowIntallMetamask(false);
            }}
            footer={null}
            closable={true}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                fontFamily: 'Roboto',
              }}
            >
              <Typography.Title level={4} style={{textAlign: 'center'}}>
                {'Please install Metamask'}
                <br/>{' '}
                <a target="_blank" href="https://metamask.io/download/">
                  https://metamask.io/download/
                </a>
              </Typography.Title>
            </div>
          </Modal>
      </div>}
    </CenterContainer>
  );
}

export default GMDRewards;
