import {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Button, Typography} from 'antd';

import {CheckCircleOutlined, InfoCircleOutlined, LoadingOutlined} from '@ant-design/icons';
import {changeStep, logout, userFormErrorMessageSelector, verifyEmail, verifyStatusSelector} from './UserForm/UseSlice';
import CenterContainer from './Layout/CenterContainer';
import {useAppDispatch, useAppSelector} from '../app/hooks';
import './VerifyPage.scss';

const {Title} = Typography;

function AccountPage() {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(verifyStatusSelector);
  const navigate = useNavigate();
  const errorMessage = useAppSelector(userFormErrorMessageSelector);

  const [searchParams,] = useSearchParams();
  let _id = searchParams.get('id');
  useEffect(() => {
    dispatch(verifyEmail(_id));
  }, []);

  return (<CenterContainer isVisible={true} className={'verify-page'}>
    <Title level={1}>Account email verify!</Title>

    {loading == 'success' && <>
      <CheckCircleOutlined className={'check-icon'}/>
      <Title level={3}>Your email verified!</Title>
      <Button onClick={() => {
        dispatch(logout());
        dispatch(changeStep('login'));
        navigate('/login');
      }} type="default" ghost size={'large'}>Return to Sign in</Button>
    </>}
    {loading == 'loading' && <>
      <LoadingOutlined className={'check-icon'}/>
      <Title level={3}>Your email is verifying!</Title>
    </>}
    {loading == 'fail' && <>
      <InfoCircleOutlined className={'check-icon'}/>
      <Title level={3}>Verify email not successful!</Title>
      {errorMessage && <Title level={3}>{errorMessage}</Title>}
      <Button onClick={() => {
        dispatch(logout());
        dispatch(changeStep('login'));
        navigate('/login');
      }} type="default" ghost size={'large'}>Return to Sign in</Button>
    </>}

  </CenterContainer>);
}

export default AccountPage;
