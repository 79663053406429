import {useEffect, useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {Button, notification, Statistic, Typography} from 'antd';

import {ActiveNetworks} from 'helpers/config';
import {URLS} from 'helpers/chains';
import {ethers} from 'ethers';
import {COLORS, commonStyle} from 'helpers/styles';
import moment from 'moment';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {userInfoSelector} from '../UserForm/UseSlice';
import CenterContainer from '../Layout/CenterContainer';
import './AFFReward.scss';
import {
  affTiersSelector, appLoadingStatusSelector,
  clamStatusSelector,
  loadMineShardStatus,
  mineShardStatusSelector
} from '../../app/AppConfigSlice';
import {claimShard, claimShardSelector} from '../Shard/ShardSlice';

const {Title} = Typography;

const {Countdown} = Statistic;

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: 'Zen Kaku Gothic Antique',
    color: COLORS.text,
    flex: '1 1 0%',
    maxWidth: 1320,
    width: '100%',
    rowGap: 0,
    padding: 24,
    ...commonStyle.card,
    minHeight: '60vh',
  },
  text: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: 20,
    lineHeight: 1.2,
    color: COLORS.primary,
    fontFamily: 'Zen Kaku Gothic Antique',
    // margin:0,
  },
};

function AFFMint() {
  useNavigate();
  new ethers.providers.JsonRpcProvider(
    URLS[ActiveNetworks.polygon][0]
  );
  const dispatch = useAppDispatch();
  const [loading,] = useState(false);

  const userInfo = useAppSelector(userInfoSelector);
  const affTiers = useAppSelector(affTiersSelector);
  const mineShardStatus = useAppSelector(mineShardStatusSelector);
  const clamStatus = useAppSelector(clamStatusSelector);
  const appLoading = useAppSelector(appLoadingStatusSelector);
  const tier = affTiers && userInfo.points && affTiers.find(item => userInfo.points >= item.start_point && userInfo.points <= item.end_point) || {
    _id: 0,
    claim_amount: 0
  };
  const amountAvaiable = mineShardStatus?.remain && tier.claim_amount <= mineShardStatus.remain || false;
  const clamWaitRemain = clamStatus?.end && clamStatus.end - new Date().getTime() || 0;

  useEffect(() => {
    dispatch(loadMineShardStatus());
  }, []);

  let claimShardStatus = useAppSelector(claimShardSelector);
  useEffect(() => {
    if (claimShardStatus) {
      if (claimShardStatus.data) {
        notification.success({message: 'Success'});
        dispatch(loadMineShardStatus());
      } else if (claimShardStatus.error) {
        notification.error({message: claimShardStatus.error});
      }
    }
  }, [claimShardStatus])
  if (!userInfo?.uid) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  return (
    <CenterContainer isVisible={true} width={1300} height={800} className={'aff-reward-container'}>
      {(appLoading != 'loading' && !loading) && <div className={'aff-reward-inner-container'}>
        <Title level={2}>Welcome to Shard Mine Season I</Title>
        {userInfo?.uid && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <p style={styles.text}>
              SHARD Remaning in Mine:&nbsp;{' '}
              <a>{mineShardStatus?.remain?.toFixed(2) || '--'}</a>
            </p>

            <p style={styles.text}>
              You have:&nbsp;{' '}
              <a>{userInfo.shard || '0'}</a>{' '}
              &nbsp;Shard
            </p>
            <p style={styles.text}>
              Your mining level:&nbsp;{' '}
              <a>{tier._id}</a>
            </p>
            {/* <p
            style={styles.text}
          >
            Your points: {User.points||"0"}
          </p> */}

            <p style={styles.text}>
              Mining Power:&nbsp;{' '}
              <a>{tier.claim_amount.toFixed(2)}</a>{' '}
              &nbsp;Shard/Day
            </p>
            {(!amountAvaiable &&
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button type="error" size="large">
                  Not Available
                </Button>
              </div>
            ) 
            // || (!userInfo.isVerifiedEmail &&
            //   <div style={{textAlign: 'center', fontSize: 20, color: 'red'}}>
            //     Please verify your email to start mining{' '}
            //   </div>
            // )
             || (clamWaitRemain > 0 && <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div>{'Mining '}</div>
                <Countdown
                  title=""
                  value={moment(clamStatus.end)}
                />
              </div>
            ) || <div style={{display: 'flex', justifyContent: 'center'}}>
              <Button type="primary" size="large" onClick={()=>dispatch(claimShard())}>
                Start Mining (24h)
              </Button>
            </div>}
          </div>

        )}
      </div>}
    </CenterContainer>
  );
}

export default AFFMint;