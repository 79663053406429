import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_User_ClaimShard } from '../../helpers/config';
import { RootState } from '../../app/store';
import { notification } from 'antd';

export interface ClaimShard {
  // claim_amount?: number,
  // claim_date?: number,
  // end?: number,
  // status?: number,
  error?: string
  data?: string
}

export interface ShardState {
  status: 'loading' | 'ready',
  claimShard?: ClaimShard
}

const initialState: ShardState = {status: 'ready'}

export const shardSlice = createSlice({
  name: 'shard',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => builder
    .addCase(claimShard.pending, (state) => {
      state.status = 'loading';
      state.claimShard = undefined;
    })
    .addCase(claimShard.rejected, state => {
      state.status = 'ready';
      notification.error({message: 'Claim Shard not successful'});
    })
    .addCase(claimShard.fulfilled, (state, action: PayloadAction<ClaimShard>) => {
      state.claimShard = action.payload;
      state.status = 'ready';
    })
})

export const claimShard = createAsyncThunk('shard/claim', async () => {
  return await fetch(API_User_ClaimShard, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    mode: 'cors'
  }).then(response => response.json())
})

export default shardSlice.reducer;
export const claimShardSelector = (state: RootState) => state.shard.claimShard;
export const shardLoadingStatusSelector = (state: RootState) => state.shard.status;