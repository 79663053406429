import { Modal, ModalProps } from 'antd';

interface OmsModalProps extends ModalProps {
  modalName: string
}

const OmsModal = (_props: OmsModalProps) => {
  const {modalName, open, ...props} = _props;
  return <Modal open={open} {...props} />;
}

export default OmsModal;