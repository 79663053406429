import { Alert, Button, Col, Divider, Form, FormProps, Input, Row, Typography } from 'antd';
import classNames from 'classnames';
import {
  changeStep,
  googleTokenSelector,
  userFormErrorSelector, signupGoogle, signupWallet, walletSignSelector
} from './UseSlice';
import LoginFormContainer from './LoginFormContainer';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useEffect, useState } from 'react';
import { FieldData } from 'rc-field-form/es/interface';

const {Title} = Typography;

export default (props: FormProps) => {
  const dispatch = useAppDispatch();
  const googleToken = useAppSelector(googleTokenSelector);
  const sig = useAppSelector(walletSignSelector);
  const formErrors = useAppSelector(userFormErrorSelector) || {};
  const [error, setError] = useState<String>();

  const onLoginFinish = (value: any) => {
    if (value.credential){
      dispatch(signupGoogle(value))
    } else{
      dispatch(signupWallet(value));
    }

  };
  useEffect(() => {
    let fields: FieldData[] = [];
    if (Object.keys(formErrors).length > 0){
      for (let key in formErrors) {
        let fieldData: FieldData = {
          name: key, errors: formErrors[key]
        }
        fields = fields.concat(fieldData);
      }
      form.setFields(fields);
    }
    if (formErrors.form){
      setError(formErrors.form)
    }
  }, [formErrors]);
  const [form] = Form.useForm();
  return <LoginFormContainer step={'change-email'}>
    <Form form={form} className={classNames('login-form', props.className)} name={'userModel'} layout={'vertical'}
          onFinish={onLoginFinish} initialValues={{email: ''}}>
      <Form.Item name={'provider'} initialValue={'resendEmail'} hidden={true}/>
      <Form.Item name={'credential'} initialValue={googleToken} hidden={true}/>
      <Form.Item name={'sig'} initialValue={sig} hidden={true}/>
      <Title level={2}>Your account is not verified</Title>
      <Title level={4}>Please check your email again.</Title>
      <Form.Item className={'full-width'}>
        <Button type={'primary'} onClick={() => dispatch(changeStep('login'))}>Back to login</Button>
      </Form.Item>
      <Divider>OR</Divider>
      <Title level={4}>Try again with this email</Title>
      {error && <Form.Item>
        <Alert type={'error'} message={error}></Alert>
      </Form.Item>}
      <Row gutter={[16, 16]}>
        <Col sm={24} xs={24}>
          <Form.Item name={'email'}
                     wrapperCol={{xs: 24}}
                     rules={[{required: true, message: 'Please input your email!'}, {
                       type: 'email',
                       message: 'The input is not valid E-mail!'
                     }]}>
            <Input placeholder={'Email'}/>
          </Form.Item>
        </Col>
        <Col sm={24} xs={24}>
          <Form.Item className={'full-width'}>
            <Button type={'primary'} htmlType="submit">Submit</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  </LoginFormContainer>;
};
