import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface MobileMenuState {
  menuOpen: boolean
}

const initialState: MobileMenuState = {menuOpen: false}

export const mobileMenuSlice = createSlice({
  name: 'mobileMenu',
  initialState: initialState,
  reducers: {
    updateOpen: (state, action: PayloadAction<boolean>) => {
      state.menuOpen = action.payload
    }
  }
});

export default mobileMenuSlice.reducer;
export const {updateOpen} = mobileMenuSlice.actions;
export const menuOpenSelector = (state: RootState) => state.mobileMenu.menuOpen;