import { Typography } from 'antd';
import { useAppDispatch } from '../../app/hooks';
import './RegisterThanksYou.scss';
import CenterContainer from '../Layout/CenterContainer';
import { useNavigate } from 'react-router-dom';

const {Title} = Typography;
const RegisterThanksYou = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return <CenterContainer isVisible={true} className={'signup-thankyou'}>
    <Title level={1}>Please check your email to finish registration!</Title>
    <Title level={3}>A confirmation has been sent to your inbox.</Title>
  </CenterContainer>
}

export default RegisterThanksYou;