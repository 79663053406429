import { HTMLAttributes, useEffect } from 'react';
import { Button, Form, Input, notification, Typography } from 'antd';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import './ChangePassword.scss';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  requestStatusSelector,
  resetPassword,
  setUserFormErrorMessage,
  userFormErrorMessageSelector
} from '../UserForm/UseSlice';
import CenterContainer from '../Layout/CenterContainer';
import NotifyForm from '../UserForm/NotifyForm';

const {Title} = Typography;
const {Password} = Input;

interface ChangePasswordProp extends HTMLAttributes<HTMLDivElement> {

}

export default (_props: ChangePasswordProp) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = useForm();
  const requestStatus = useAppSelector(requestStatusSelector);
  const errorMessage = useAppSelector(userFormErrorMessageSelector);

  useEffect(() => {
    errorMessage && notification.error({message: errorMessage})
    dispatch(setUserFormErrorMessage(undefined));
  }, [errorMessage])
  return <CenterContainer isVisible={true}>
    {requestStatus !== 'rspw-success' &&
      <Form className={classNames('reset-password-form')} name={'userModel'} layout={'vertical'}
            form={form} onFinish={(values) => {
        dispatch(resetPassword(values));
      }}>
        <Title level={1}>Change password</Title>
        <Form.Item name={'currentPassword'} label={'Current Password'}
                   wrapperCol={{sm: 24, xs: 24}}
                   rules={[{required: true, message: 'Please input your current password!'},
                     {
                       min: 6,
                       message: 'Password min length is 6 characters'
                     },
                     {
                       max: 70,
                       message: 'Password too long'
                     },
                     {
                       pattern: /^(?=.*?[A-Z]).+$/,
                       message: 'Password must have at least one Uppercase'
                     },
                     {
                       pattern: /^(?=.*?[a-z]).+$/,
                       message: 'Password must have at least one lowercase'
                     },
                     {
                       pattern: /^(?=.*?[0-9]).+$/,
                       message: 'Password must have at least one numeric'
                     }, {
                       pattern: /^[A-Za-z0-9!@#$%^&*]+$/,
                       message: 'Password allow UPPERCASE, lowercase, numeric and special character !@#$%^&*'
                     }]}>
          <Password placeholder={'Current password'} key={'currentPassword'}/>
        </Form.Item>
        <Form.Item name={'password'} label={'Password'}
                   wrapperCol={{sm: 24, xs: 24}}
                   rules={[{required: true, message: 'Please input your password!'},
                     {
                       min: 6,
                       message: 'Password min length is 6 characters'
                     },
                     {
                       max: 70,
                       message: 'Password too long'
                     },
                     {
                       pattern: /^(?=.*?[A-Z]).+$/,
                       message: 'Password must have at least one Uppercase'
                     },
                     {
                       pattern: /^(?=.*?[a-z]).+$/,
                       message: 'Password must have at least one lowercase'
                     },
                     {
                       pattern: /^(?=.*?[0-9]).+$/,
                       message: 'Password must have at least one numeric'
                     }, {
                       pattern: /^[A-Za-z0-9!@#$%^&*]+$/,
                       message: 'Password allow UPPERCASE, lowercase, numeric and special character !@#$%^&*'
                     },
                     {
                       validator: async (rule, value) => {
                         const repeatPassword = form.getFieldValue('repeatPassword');
                         if (!repeatPassword){
                           return;
                         }
                         if (value !== repeatPassword){
                           throw new Error('Password and Repeat Password not match');
                         }
                       },
                       message: 'Password and Repeat Password not match'
                     }]}>
          <Password placeholder={'Password'} key={'password'}
                    onChange={() => form.getFieldValue('repeatPassword') && form.validateFields(['repeatPassword'])}/>
        </Form.Item>
        <Form.Item name={'repeatPassword'} label={'Repeat Password'}
                   wrapperCol={{sm: 24, xs: 24}}
                   rules={[{required: true, message: 'Please input your password again!'},
                     {
                       validator: async (rule, value) => {
                         const password = form.getFieldValue('password');
                         if (!password){
                           return;
                         }
                         if (value !== password){
                           throw new Error('Password and Repeat Password not match');
                         }
                       },
                       message: 'Password and Repeat Password not match'
                     }]}>
          <Password placeholder={'Password'}
                    onChange={() => form.validateFields(['password'])}/>
        </Form.Item>
        <Form.Item className={'full-width'}>
          <Button type={'primary'} htmlType="submit" size={'large'}>Change your password</Button>
        </Form.Item>
        <Form.Item>
          <Link to={'/account'}>Back to account page</Link>
        </Form.Item>
      </Form> || <Form>
        <NotifyForm icon={'success'} className={'reset-password-form'}>
          <Title level={3}>Password changed</Title>
          <Button type={'primary'} onClick={() => {
            navigate('/account')
          }}>Back to account page</Button>
        </NotifyForm>
      </Form>
    }
  </CenterContainer>
}