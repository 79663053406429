import { useAppDispatch, useAppSelector } from '../../app/hooks';
import React, { useEffect } from 'react';
import { linkWallet, loginUser, walletSelector } from '../UserForm/UseSlice';
import { loadNFTs, nftModalShowSelector, nftsSelector, nftStatusSelector } from './NFTBalanceSlice';
import CenterContainer from '../Layout/CenterContainer';
import { Typography, Button } from 'antd';
import './NFTBalance.scss';
import NFTLoading from './NFTLoading';
import AnimatedContainer from '../Layout/AnimatedContainer';
import NFTBalanceListView from './NFTBalanceListView';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import WalletConnectLogin from 'components/UserForm/WalletConnectLogin';
import WalletInjectLogin from 'components/UserForm/WalletInjectLogin';

const { Title } = Typography;

interface NFTBalanceProps {
}

const NFTBalance = (props: NFTBalanceProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const wallet = useAppSelector(walletSelector);
  const NFTBalances = useAppSelector(nftsSelector);
  const status = useAppSelector(nftStatusSelector);
  const modalShow = useAppSelector(nftModalShowSelector);

  let className = classNames('nft-container');
  // NFTBalances && NFTBalances.forEach((nft: any) => console.log(nft.token_id));
  useEffect(() => {
    if (wallet) {
      console.log('useEffect');
      dispatch(loadNFTs(String(wallet)));
    }
  }, [wallet]);
  useEffect(() => {
    className = modalShow ? classNames('nft-container', 'modal-show') : classNames('nft-container')
  });

  return NFTBalances?.length && wallet &&
    <CenterContainer isVisible={true} width={1200} className={className}>
      <Title level={2}>Your NFTs</Title>
      {status === 'loading' && <NFTLoading /> ||
        <AnimatedContainer animationIn={'fadeIn'} animationOut={'fadeOut'} isVisible={true}>
          <NFTBalanceListView />
        </AnimatedContainer>}
    </CenterContainer>
    || (NFTBalances?.length == 0 && wallet &&
      <CenterContainer isVisible={true} width={700} className={'nft-container-empty'}>
        <Title level={2}>Your NFTs</Title>
        <Title level={3}>You don't have any NFT yet</Title>
        {/* <Title level={4}>Get a free NFT and a magical giftbox <a href={'https://kingsumo.com/g/jm1pnz/oms-grab-your-free-nft-magical-gift-box/498zgj0'}
                                                               target={'_blank'}>here</a></Title> */}
        <Button
          type="primary"
          size="large"
          onClick={() => navigate('/mint')}
          style={{ borderRadius: 25 }}
        >
          Get One Now!
        </Button>

      </CenterContainer>)
    || (!wallet &&
      <CenterContainer isVisible={true} width={700} className={'nft-container-empty'}>
        <Title level={2}>Connect your wallet to see your NFTS</Title>
        <WalletConnectLogin type={'link'}
          onSuccess={(flatSig) => {
            dispatch(linkWallet({ sig: flatSig, walletType: 'walletconnect' })).then((res: any) => {
              dispatch(loginUser({ provider: 'Token' }));
            });
          }}></WalletConnectLogin>
        <WalletInjectLogin type={'link'} onSuccess={(flatSig) => {
          dispatch(linkWallet({ sig: flatSig, walletType: 'injected' })).then((res: any) => {
            dispatch(loginUser({ provider: 'Token' }));
          });
        }}></WalletInjectLogin>
      </CenterContainer>
    )
    ||
    <CenterContainer isVisible={true} width={700} style={{ textAlign: 'center' }} className={'nft-container-empty'}><Title
      level={2}>Your NFTs</Title>
      <Title level={3}>Please wait ...</Title>
    </CenterContainer>
}

export default NFTBalance;
