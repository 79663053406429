import { Alert, Button, Divider, Form, FormProps, Input, Modal, Typography } from 'antd';
import classNames from 'classnames';
import {
  changeStep,
  loginUser, requestStatusSelector,
  resetUserFormRequestStatus,
  sendForgotPassword,
  userFormErrorSelector
} from './UseSlice';
import {
  CredentialResponse,
  useGoogleLogin,
  TokenResponse
} from '@react-oauth/google';
import LoginFormContainer from './LoginFormContainer';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useEffect, useState } from 'react';
import { FieldData } from 'rc-field-form/es/interface';
import './LoginForm.scss';
import NotifyForm from './NotifyForm';
import WalletInjectLogin from './WalletInjectLogin';
import WalletConnectLogin from './WalletConnectLogin';
import { GoogleOutlined } from '@ant-design/icons';

const {Password} = Input;
const {Title} = Typography;

export default (props: FormProps) => {
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(requestStatusSelector);
  const formErrors = useAppSelector(userFormErrorSelector) || {};
  const [error, setError] = useState<String>();
  const [showForgotModal, setShowForgotModal] = useState<boolean>(false);

  const loginGoogle = useGoogleLogin({
    scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
    onSuccess: tokenResponse => {
      responseGoogleHandler(tokenResponse);
    }
  })

  const onLoginFinish = ({email, password}: { email: string, password: string }) => {
    dispatch(loginUser({
      provider: 'Email',
      email: email.toLowerCase(),
      password: password
    }))
  };
  const clickRegisterLink = () => {
    dispatch(changeStep('register'));
  };
  const responseGoogleHandler = (responseGoogle: CredentialResponse & TokenResponse) => {
    console.log(responseGoogle);
    dispatch(loginUser({
      credential: responseGoogle.credential,
      accessToken: responseGoogle.access_token,
      provider: 'Google'
    }))
    ;
  };

  useEffect(() => {
    let fields: FieldData[] = [];
    if (Object.keys(formErrors).length > 0) {
      for (let key in formErrors) {
        let fieldData: FieldData = {
          name: key, errors: formErrors[key]
        }
        fields = fields.concat(fieldData);
      }
      if (showForgotModal) {
        formForgot.setFields(fields);
      } else {
        formLogin.setFields(fields);
      }
    }
    if (formErrors.form) {
      setError(formErrors.form)
    }
  }, [formErrors]);
  useEffect(() => {
    dispatch(resetUserFormRequestStatus);
  }, [showForgotModal])

  const [formLogin] = Form.useForm();
  const [formForgot] = Form.useForm();
  return <LoginFormContainer step={'login'}>
    {!showForgotModal && <Form form={formLogin} className={classNames('login-form', props.className)} name={'userModel'}
                               layout={'vertical'}
                               onFinish={onLoginFinish} onValuesChange={() => setError(undefined)}>
      <Title level={1}>Sign in</Title>
      {error && <Form.Item>
        <Alert type={'error'} message={error}></Alert>
      </Form.Item>}
      <Form.Item name={'email'}
                 wrapperCol={{sm: 24, xs: 24}}
                 rules={[{required: true, message: 'Please input your email!'},
                   {type: 'email', message: 'Please input a valid email!'}]}>
        <Input placeholder={'Email'} key={'email'}/>
      </Form.Item>
      <Form.Item name={'password'}
                 wrapperCol={{sm: 24, xs: 24}}
                 rules={[{required: true, message: 'Please input your password!'}]}>
        <Password placeholder={'Password'} key={'password'}/>
      </Form.Item>
      <Form.Item>
        Forgot your password? <a onClick={() => {
        setShowForgotModal(true);
      }
      }>Click here!</a>
      </Form.Item>
      <Form.Item className={'full-width'}>
        <Button type={'primary'} htmlType="submit" size={'large'}>Sign in</Button>
      </Form.Item>
      <Divider>OR</Divider>
      <Form.Item className={'full-width'}>
        <Button icon={<GoogleOutlined/>} onClick={() => loginGoogle()} type={'primary'} size={'large'}>
          Google
        </Button>
      </Form.Item>
      <Form.Item className={'full-width'}><WalletInjectLogin
        onSuccess={(sign) => dispatch(loginUser({provider: 'Wallet', sig: sign, walletType: 'injected'}))}/></Form.Item>
      <Form.Item className={'full-width'}><WalletConnectLogin
        onSuccess={(sign) => dispatch(loginUser({
          provider: 'Wallet',
          sig: sign,
          walletType: 'walletconnect'
        }))}/></Form.Item>
    </Form>}
    <Modal open={showForgotModal} footer={null} onCancel={() => setShowForgotModal(false)} centered={true}
           className={'forgot-password-modal'}>
      {requestStatus !== 'fgpw-success' &&
        <Form form={formForgot} onFinish={({email}: { email: string }) => {
          dispatch(sendForgotPassword(email));
        }}>
          <Title level={3}>Request reset email</Title>
          <Form.Item name={'email'}
                     wrapperCol={{sm: 24, xs: 24}}
                     rules={[{required: true, message: 'Please input your email!'},
                       {type: 'email', message: 'Please input a valid email!'}]}>
            <Input placeholder={'Email'} key={'email'}/></Form.Item>
          <Form.Item className={'full-width'}>
            <Button type={'primary'} htmlType="submit" size={'large'}>Reset password</Button>
          </Form.Item>
          <Divider>OR</Divider>
          <Button type={'link'} onClick={() => {
            setShowForgotModal(false);
          }
          }>Return to login</Button>
        </Form> || <NotifyForm icon={'success'}>
          <Title level={3}>Email sent</Title>
          <Title level={4}>Check your email and open the link we sent to continue!</Title>
          <Button onClick={() => {
            setShowForgotModal(false);
            dispatch(resetUserFormRequestStatus());
          }} type={'primary'}>Back</Button>
        </NotifyForm>}
    </Modal>
  </LoginFormContainer>;
};
