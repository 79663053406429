import { Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';
import classNames from 'classnames';
import { TextAlignType, TextColorType } from './Types';

const {Title} = Typography;

interface OmsTitleProps extends TitleProps {
  textAlign?: TextAlignType;
  textColor?: TextColorType;
}

const OmsTitle = (props: OmsTitleProps) => {
  const {className, textAlign, textColor, ..._props} = props;
  return <Title className={classNames('oms-title', textAlign, textColor, className)} {..._props}></Title>
}

export default OmsTitle;