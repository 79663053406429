import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface HomePhaserState {
  gameLoading: boolean
  game?: any
}

const initialState: HomePhaserState = {
  gameLoading: false
}

const homePhaserSlice = createSlice({
  name: 'aldoria',
  initialState,
  reducers: {
    setGameLoading: (state, action: PayloadAction<boolean>) => {
      state.gameLoading = action.payload;
    },
    setGame: (state, action: PayloadAction<any>) => {
      state.game = action.payload;
    }
  }
});

export default homePhaserSlice.reducer;
export const {setGameLoading, setGame} = homePhaserSlice.actions;
export const gameLoadingSelector = (state: RootState) => state.homePhaser.gameLoading;
export const gameSelector =  (state: RootState) => state.homePhaser.game;