import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import { WalletConnect } from '@web3-react/walletconnect-v2';
import { hooks as metaMaskHooks, metaMask } from '../connectors/metaMask';
import { hooks as walletConnectHooks, walletConnect } from '../connectors/walletConnectV2';
import { ReactNode } from 'react';

const connectors: [MetaMask | WalletConnect, Web3ReactHooks][] = [
  [metaMask, metaMaskHooks],
  [walletConnect, walletConnectHooks],
];


export function Application({children}: { children: ReactNode | ReactNode[] }) {
  let web3ReactProvider = <><Web3ReactProvider connectors={connectors}>
    {children}
  </Web3ReactProvider></>;
  return web3ReactProvider;
}
