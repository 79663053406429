import {useEffect, useRef, useState} from 'react';

// import "@uniswap/widgets/dist/fonts.css";
import {notification, Typography} from 'antd';
import {useNavigate} from 'react-router-dom';
import {useWeb3React} from '@web3-react/core';
import {API_TWITTERAUTH} from 'helpers/config';
import * as CommonHelper from 'helpers/common';
import {COLORS, commonStyle} from 'helpers/styles';

function getParameterByName(name) {
  if (name !== '' && name !== null && name !== undefined) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
      results = regex.exec(window.location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  } else {
    var arr = window.location.href.split('/');
    return arr[arr.length - 1];
  }
}

// import Web3 from "web3";
// use(Web3ClientPlugin);

const { Title } = Typography;
const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: '\'Zen Kaku Gothic Antique\', sans-serif',
    color: COLORS.text,
    flex: '1 1 0%',
    maxWidth: 1320,
    width: '100%',
    rowGap: 0,
    padding: 24,
    ...commonStyle.card,
  },
};
const oauth_token = getParameterByName('oauth_token');
const oauth_verifier = getParameterByName('oauth_verifier');
function TWCallback() {
  let navigate = useNavigate();
  // const child_provider = new ethers.providers.JsonRpcProvider(
  //   URLS[ActiveNetworks.polygon][0]
  // );
  const { account, isActive } = useWeb3React();
  const [loading, setLoading] = useState(false);
  const [updateNow] = useState(0);
  const myRef = useRef(null);
  useEffect(() => {
    if (account) loadData();
  }, [account]);
  const loadData = async () => {
    console.log(oauth_token.length, oauth_verifier.length, account);
    if (oauth_token.length == 0 || oauth_verifier.length == 0) {
      navigate('/register');
      return;
    }
    if (account) {
      let rel = await CommonHelper.postJSON(API_TWITTERAUTH, {
        oauth_token: oauth_token,
        oauth_verifier: oauth_verifier,
        account: account.toLowerCase(),
      });
      if (rel) {
        if (rel.status == 1) {
          // CommonHelper.saveLocalData("TWITTER",rel.data);
          //
          // console.log(myRef);
          // clickElement(myRef);
          notification.success({
            message: 'Success',
          });
        } else {
          console.log('rel');
          notification.error({
            message: rel.message,
          });
        }
      } else {
        notification.error({
          message: 'Please try again',
        });
      }
    }
    setTimeout(() => navigate('/register'), 2000);
  };
  // const clickElement = (ref) => {
  //   ref.current.click();
  // };
  return (
    <div style={styles.wrapper}>
      <div>
        <center className="loadingCenter">
          <img src="./loading_logo.png" style={{ maxWidth: 200 }}></img>
          {/* <div><TwitterShareButton
            url={"app.badboygorillamob.com/register"}
            title={"🎉 Just registered for the Bad Boy Gorilla Mob Interest List @BBGorillaMob on their website!"}
            className=""

            ref={myRef}
          >
            
          </TwitterShareButton></div> */}
        </center>
      </div>
    </div>
  );
}

export default TWCallback;
