import { useEffect, useState } from 'react';
import * as CommonHelper from 'helpers/common';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { firstNameSelector, lastNameSelector, stepSelector } from '../UserForm/UseSlice';
// import { metaMask } from 'connectors/metaMask';
// import { walletConnect  } from 'connectors/walletConnect';

const styles = {
  account: {
    height: '42px',
    padding: '0 15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: '12px',
    backgroundColor: 'rgb(244, 244, 244)',
    cursor: 'pointer',
  },
  text: {
    color: '#21BF96',
  },
  connector: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '20px 5px',
    cursor: 'pointer',
  },
  icon: {
    alignSelf: 'center',
    fill: 'rgb(40, 13, 95)',
    flexShrink: '0',
    marginBottom: '8px',
    height: '30px',
  },
} as const;

function Account() {
  useAppSelector(firstNameSelector);
  useAppSelector(lastNameSelector);
  const isLogedIn = useAppSelector(stepSelector) === 'logged-in';

  const [, setUpdateNow] = useState(0);

  useEffect(() => {
    //const { walletConnect  } =  require('connectors/walletConnect');
    //walletConnect.activate(1);
    CommonHelper.getStore().subscribe(() => {
      setUpdateNow((updateNow) => (updateNow > 100 ? 0 : ++updateNow));
    });
  }, []);


  if (!isLogedIn){
    return (
      <>
        <div>
          <RouterLink style={styles.text} to={'/login'}>Sign In</RouterLink>
        </div>

      </>
    );
  }

  return (
    <>
      <div

      >
        <NavLink to={'/account'} className={({isActive}) =>
          isActive ? 'menu-active' : undefined}>Account</NavLink>
      </div>


    </>
  );
}

export default Account;
