import SiteStore from './store/site';
import preval from 'preval.macro'

export const buildDate = preval`
const moment = require('moment')
module.exports = moment().format('YYYYMMDD')
`
export function getStore() {
  return SiteStore;
}
export async function loadJSONData(url) {
  try {
    const response = await fetch(url);
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function dataUrlToFile(dataUrl, fileName, type) {
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], fileName, { type });
}
export async function postFormData(url, data) {
  var formBody = [];
  for (var property in data) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }
  formBody = formBody.join('&');
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: formBody,
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export async function postFormData2(url, data) {
  // console.log(url);
  // return;
  let formData = new FormData();
  // Assume "photo" is the name of the form field the server expects

  for (var property in data) {
    formData.append(property, data[property]);
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
      header: {
        'content-type': 'multipart/form-data',
      },
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export async function postJSON(url, data) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      mode: 'cors'
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function getLocalData(key) {
  let data = localStorage.getItem(key);
  if (data) {
    data = JSON.parse(data);
  }
  return data;
}

export function saveLocalData(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function deleteLocalData(key) {
  localStorage.removeItem(key);
}

export function getInitials(name = '') {
  return name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('');
}

export function ValidateEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  // alert("You have entered an invalid email address!")
  return false;
}
