import React from 'react';
import SocialLogin, { Props as SocialLoginProps } from 'react-social-login';
import { Button, ButtonProps, Col, Row } from 'antd';
import classNames from 'classnames';
import OmsText from '../CustomComponent/OmsText';

interface SocialButtonProps extends ButtonProps, SocialLoginProps {
  connectedId?: string;
}

class _SocialButton extends React.Component<SocialButtonProps, {}> {
  render() {
    const {children, triggerLogin, ...props} = this.props;
    return <Button onClick={triggerLogin}  {...props} > {children}</Button>;
  }
}

class SocialButton extends React.Component<SocialButtonProps, { labelId?: string }> {
  render() {
    const {children, triggerLogin, className, provider, labelId, ...props} = this.props;
    return props.connectedId &&
      <Row gutter={10}>
        <Col md={8} sm={24} xs={24}><OmsText textType={'label'}>{labelId}</OmsText></Col>
        <Col md={16} sm={24} xs={24}><OmsText textType={'text-value'}>{props.connectedId}</OmsText></Col>
      </Row> ||
      <Button onClick={triggerLogin}  {...props}
              className={classNames('social-button', className)}> {children}</Button>;
  }
}

export default SocialLogin(SocialButton);