import { SerializedError, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { postJSON } from 'helpers/common'
import { GET_ALL_RESOURCE } from 'helpers/config'
export interface ResourceInfo {
    id: string,
    name: string
    type: string
    category: string
}

interface GameResourcesInfo {
    status: 'loading' | 'ready',
    allResource: ResourceInfo[],
    currentRequestId: string | undefined,
    error: null | SerializedError,
}

const initialState: GameResourcesInfo = {
    status: 'loading',
    currentRequestId: undefined,
    allResource: [],
    error: null,
}

export const resourceSlide = createSlice({
    name: 'allResource',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => builder
        .addCase(loadAllResources.pending, (state, action) => {
            if (state.status === 'loading') {
                state.currentRequestId = action.meta.requestId
            }
        })
        .addCase(loadAllResources.fulfilled, (state, action) => {
            const { requestId } = action.meta
            if (
                state.status === 'loading' &&
                state.currentRequestId === requestId
            ) {
                state.status = 'ready'
                state.allResource = action.payload
                state.currentRequestId = undefined
            }
        })
        .addCase(loadAllResources.rejected, (state, action) => {
            const {requestId} = action.meta
            if (
                state.status === 'loading' &&
                state.currentRequestId === requestId
            ) {
                state.status = 'ready'
                state.error = action.error
                state.currentRequestId = undefined               
            }
        })
})

export const loadAllResources = createAsyncThunk('allResources/load', async () => {
    const resData = await postJSON(GET_ALL_RESOURCE);
    return resData.data;
})

export default resourceSlide.reducer;

export const loadAllResourceSelector = (state: RootState) => state.gameResourceInfo.allResource;
export const allResourceStatusSelector = (state: RootState) => state.gameResourceInfo.status;
