import * as React from 'react';
import classNames from 'classnames';
import './index.scss';
import { NavLink, useLocation } from 'react-router-dom';
import Account from '../Account/Account2';
import AnimatedContainer from '../Layout/AnimatedContainer';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { stepSelector } from '../UserForm/UseSlice';
import { menuOpenSelector, updateOpen } from '../MobileMenu/MobileMenuSlice';
import { useEffect, useState } from 'react';
import { Button } from 'antd';
import Title from 'antd/lib/typography/Title';
import OmsModal from 'components/CustomComponent/OmsModal';

interface BurgerMenuProps extends React.HTMLAttributes<HTMLDivElement> {

}

const BurgerMenu = (props: BurgerMenuProps) => {
  const open = useAppSelector(menuOpenSelector);
  const stepLogin = useAppSelector(stepSelector);
  const dispatch = useAppDispatch();
  const location = useLocation();


  useEffect(
    () => {
      dispatch(updateOpen(false));
    },
    [location]
  )

  const [showPopupDisableClaimShard, setshowPopupDisableClaimShard] = useState(false);

  const showModal = () => {
    setshowPopupDisableClaimShard(true);
  };

  const handleOk = () => {
    setshowPopupDisableClaimShard(false);
  };

  return <>
    <div className={classNames('burger-menu', open ? 'open' : 'close')}
      onClick={() => dispatch(updateOpen(!open))}>
      <div className="bar1"></div>
      <div className="bar2"></div>
      <div className="bar3"></div>
    </div>
    <AnimatedContainer animationIn={'fadeIn'} animationOut={'fadeOut'} isVisible={open}
      animationInDuration={100} animationOutDuration={100}
      className={classNames('mobile-menu', open ? 'open' : 'close')}>
      <div className={'container-outer'}>
        <div className={'container-inner'}>
          <img className={'logo-menu'} src="/logo-tower-500.png" alt="One Magic School" />
          <ul>
            {stepLogin === 'logged-in' && <li>
              <NavLink
                to="/home"
                className={({ isActive }) => (isActive ? 'menu-active' : undefined)}
              >Home</NavLink>
            </li>}
            {stepLogin === 'logged-in' && <li>
              <NavLink
                to=""
                className={({ isActive }) => (isActive ? 'menu-active' : undefined)}
                onClick={() => setshowPopupDisableClaimShard(true)}
              >
                Claim Shard
              </NavLink>
            </li>}
            {stepLogin === 'logged-in' && <li>
              <NavLink
                to="/nfts"
                className={({ isActive }) => (isActive ? 'menu-active' : undefined)}
              >NFTS</NavLink>
            </li>}
            {stepLogin === 'logged-in' && <li>
              <NavLink
                to="/mint"
                className={({ isActive }) => (isActive ? 'menu-active' : 'menu-highlight')}>Mint</NavLink>
            </li>}
            {stepLogin === 'logged-in' && <li>
              <NavLink
                to="/shard_mine"
                className={({ isActive }) => (isActive ? 'menu-active' : undefined)}>SHARD Mine</NavLink>
            </li>}
            {stepLogin === 'logged-in' && <li><NavLink
              to="/game_demo"
              className={({ isActive }) => (isActive ? 'menu-active' : undefined)}>Game Demo
            </NavLink>
            </li>}
            {stepLogin === 'logged-in' && <li><NavLink
              to="/aldoria"
              className={({ isActive }) => (isActive ? 'menu-active' : undefined)}>Aldoria
            </NavLink>
            </li>}
            {stepLogin === 'logged-in' && <li className={'account'}><Account /></li>}
          </ul>
          <OmsModal
            modalName={'Disable claim shard popup'}
            open={showPopupDisableClaimShard}
            centered={true}
            footer={null} closable={false}
            className={'popup-comming-soon'}
          >
            <div>
              <div className={'body-wrapper'} style={{ textAlign: 'center' }}>
                <Title level={2}>{'Comming soon'}</Title>
              </div>
              <div className={'footer-wrapper'}>
                <Button type={'primary'} onClick={() => {
                  handleOk()
                }}>OK
                </Button>
              </div>
            </div>
          </OmsModal>
        </div>
      </div>
    </AnimatedContainer>
  </>
};

export default BurgerMenu;