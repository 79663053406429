import Phaser, { BlendModes } from 'phaser';
import GlowFilterPipelinePlugin from 'phaser3-rex-plugins/plugins/glowfilter2pipeline-plugin';
import Pubsub from 'pubsub-js';
import 'phaser/plugins/spine/dist/SpinePlugin';
import {isMobile} from 'react-device-detect';

const ZOOM_SCALE = 1.05;
const PUBSUB_WINDOW_RESIZE = 'window:resize';
const PUBSUB_COMING_SOON_OPEN = 'comingsoon:open';
const PUBSUB_NAVIGATE_TO = 'navigate:to';
const PUBSUB_PHASER_IN = 'phaser:in';

export default class MainScene extends Phaser.Scene {
  public test: any = 'MainScene';
  private smokes: any = {smokes1: [], smokes2: [], smokes3: []};
  private clouds: any = [];
  private tick: number = 0;
  private towerLights?: TowerLight[];
  private runeCircle?: RuneCircle;
  private phaserIn: boolean = false;
  private imgOverlays: Phaser.GameObjects.Image[] = [];
  private textOverlays: Phaser.GameObjects.Text[] = [];
  constructor() {
    super({
      key: 'MainScene'
    });
  }
  init() {
    //this.cameras.main.setBackgroundColor('#24252A')
    //this.load.image('map', 'main_bg.jpg');
  }

  preload() {
    console.log('preload');
    this.load.image('map', '/home/Ground.png');
    this.load.image('particle', '/smoke0.png');
    // this.load.image("w0", "/w0.png");
    // this.load.image("w1", "/w1.png");
    // this.load.image("w2", "/w2.png");
    this.load.image('sky', '/home/background_sky.jpg');
    this.load.image('clouds', '/home/clouds.png');
    this.load.image('foreground_overlay', '/home/foreground_overlay.png');
    this.load.image('castle', '/home/Castle.png');
    this.load.image('cottage', '/home/Cottage.png');
    this.load.image('forge', '/home/Forge.png');
    this.load.image('inn', '/home/Inn.png');
    this.load.image('mine', '/home/Mine.png');
    this.load.image('shard', '/home/Shard.png');
    this.load.image('store', '/home/Store.png');
    this.load.image('cloud1', '/home/Cloud1.png');
    this.load.image('cloud2', '/home/Cloud2.png');
    this.load.image('cloud3', '/home/Cloud3.png');
    this.load.image('cloud4', '/home/Cloud4.png');
    this.load.image('cloud5', '/home/Cloud5.png');
    this.load.image('cloud6', '/home/Cloud6.png');
    this.load.image('tower_light', '/home/tower-light.png');
    this.load.image('rune_circle', '/home/rune-circle.png');
    // this.load.image('mint_here', '/home/Mint_here_arrow.svg');
    this.load.plugin(
      'rexglowfilter2pipelineplugin',
      GlowFilterPipelinePlugin,
      true
    );

    this.load.setPath('/home/Foreground');
    this.load.spine('foreground', 'Dapp_Foreground.json', 'Dapp_Foreground.atlas', true);
  }

  create() {
    let thisScene = this;

    Pubsub.subscribe(PUBSUB_PHASER_IN, (topic: string, phaserIn: boolean) => {
      console.log('phaserIn', phaserIn);
      thisScene.phaserIn = phaserIn;
    })
    var cam = this.cameras.main;

    const sky = this.add.image(1000, 150, 'sky');
    var cloud1 = this.add
      .image(700, 50, 'cloud1');
    var cloud2 = this.add
      .image(900, 120, 'cloud2');
    var cloud3 = this.add
      .image(1100, 200, 'cloud3');
    var cloud4 = this.add
      .image(1400, 70, 'cloud4');
    var cloud5 = this.add
      .image(1200, 90, 'cloud5');
    var cloud6 = this.add
      .image(1600, 150, 'cloud6');
    //const clouds = this.add.image(1000, 150, 'clouds');
    /**
     * this.cameras.main.setBounds(0, 0, 2000, 1157);
     */
    var map = this.add.image(0, 0, 'map').setOrigin(0, 0);

    this.add.image(1615, 557, 'forge');
    var forge_overlay = this.initGlow(this.add.image(1615, 557, 'forge'))

    this.add.image(1727, 475, 'mine');
    var mine_overlay = this.initGlow(this.add.image(1727, 475, 'mine'))

    this.add.image(1409, 524, 'cottage');
    var cottage_overlay = this.initGlow(this.add.image(1409, 524, 'cottage'))

    this.add.image(800, 475, 'inn')
    var inn_overlay = this.initGlow(this.add.image(800, 475, 'inn'))

    this.add.image(296, 488, 'store')
    var store_overlay = this.initGlow(this.add.image(296, 488, 'store'))

    this.add.image(1644, 231, 'castle')
    var castle_overlay = this.initGlow(this.add.image(1644, 231, 'castle'))

    this.towerLights = [new TowerLight(this.add.image(1665, 177, 'tower_light').setBlendMode(BlendModes.HARD_LIGHT)),
      new TowerLight(this.add.image(1665, 177, 'tower_light').setBlendMode(BlendModes.HARD_LIGHT))]
    this.runeCircle = new RuneCircle(this.add.image(1665, 177, 'rune_circle').setBlendMode(BlendModes.COLOR_BURN).setScale(.5));

    this.add.image(1007, 601, 'shard')
    var shard_overlay = this.initGlow(this.add.image(1007, 601, 'shard'))

    //FRONT LAYER

    // this.add.image(0, 0, 'foreground').setOrigin(0, 0);
    this.add.spine(1000, 562.5, 'foreground', 'animtion0', true);
    var foreground_overlay = this.initGlow(this.add.image(0, 0, 'foreground_overlay').setOrigin(0, 0))


    //TEXT LAYER
    var store_text = this.add.text(296, 488, 'Shop', {
      align: 'center', fontSize: '50px', color: '#049DE3', fontFamily: 'Zen Kaku Gothic Antique'
    }).setShadow(0, 0, '#333', 10, true).setStroke('#fff', 2).setBlendMode(BlendModes.DARKEN).setAlpha(isMobile?1:0)

    var game_demo_text = this.add.text(900, 950, ['Wild land'], {
      align: 'center', fontSize: '70px', color: '#049DE3', fontFamily: 'Zen Kaku Gothic Antique'
    }).setShadow(0, 0, '#333', 10, true).setStroke('#fff', 2).setBlendMode(BlendModes.DARKEN).setAlpha(isMobile?1:0)
    // game_demo.body = {type: 'trapezoid', width: 100, height: 100}

    var shard_mine_text = this.add.text(1600, 375, 'Shard mine', {
      fontSize: '40px', color: '#049DE3', fontFamily: 'Zen Kaku Gothic Antique'
    }).setShadow(0, 0, '#333', 10, true).setStroke('#fff', 2).setBlendMode(BlendModes.DARKEN).setAlpha(isMobile?1:0)

    var mint_text = this.add.text(1620, 300, ['Aldoria'], {
      fontSize: '30px', color: '#049DE3', fontFamily: 'Zen Kaku Gothic Antique', align: 'center'
    }).setShadow(0, 0, '#333', 10, true).setStroke('#fff', 2).setBlendMode(BlendModes.DARKEN).setAlpha(isMobile?1:0)
  //   var mint_here = this.add.image(1580, 180, 'mint_here').setBlendMode(BlendModes.DARKEN);
  //   mint_here.setScale(0.3)
  //   var tween = this.tweens.add({
  //     targets: mint_here,
  //     y: 200,
  //     ease: 'Power1',
  //     duration: 2000,
  //     yoyo: true,
  //     repeat: -1,
  // });
    var nft_text = this.add.text(800, 350, 'Inn', {
      fontSize: '50px', color: '#049DE3', fontFamily: 'Zen Kaku Gothic Antique'
    }).setShadow(0, 0, '#333', 10, true).setStroke('#fff', 2).setBlendMode(BlendModes.DARKEN).setAlpha(isMobile?1:0)

    var cottage_text = this.add.text(1280, 375, 'Cottage', {
      fontSize: '50px', color: '#049DE3', fontFamily: 'Zen Kaku Gothic Antique'
    }).setShadow(0, 0, '#333', 10, true).setStroke('#fff', 2).setBlendMode(BlendModes.DARKEN).setAlpha(isMobile?1:0)

    var forge_text = this.add.text(1735, 557, 'Forge', {
      fontSize: '50px', color: '#049DE3', fontFamily: 'Zen Kaku Gothic Antique'
    }).setShadow(0, 0, '#333', 10, true).setStroke('#fff', 2).setBlendMode(BlendModes.DARKEN).setAlpha(isMobile?1:0)

    var shard_text = this.add.text(830, 650, ['Shard monument', ], {
      fontSize: '50px', color: '#049DE3', fontFamily: 'Zen Kaku Gothic Antique'
    }).setShadow(0, 0, '#333', 10, true).setStroke('#fff', 2).setBlendMode(BlendModes.DARKEN).setAlpha(isMobile?1:0)

    this.addGlow(inn_overlay, '/nfts', this, nft_text, .7);
    this.addGlow(store_overlay, '/mint', this, store_text);
    this.addGlow(castle_overlay, '/aldoria', this, mint_text, .4);
    this.addGlow(mine_overlay, '/shard_mine', this, shard_mine_text, 1);
    this.addGlow(cottage_overlay, {'title': 'Coming soon', 'content': 'Cottage is in development'}, this, cottage_text, .7);
    this.addGlow(forge_overlay, {'title': 'Coming soon', 'content': 'Forge is in development'}, this, forge_text, 1);
    this.addGlow(shard_overlay, {'title': 'Shard monument', 'content': 'A big chunk of Shard, still glowing with magic'}, this, shard_text, 1);
    this.addGlow(foreground_overlay, '/game_demo', this, game_demo_text, .7);


    this.clouds.push({s: cloud1, r: 0.15});
    this.clouds.push({s: cloud2, r: 0.15});
    this.clouds.push({s: cloud3, r: 0.15});
    this.clouds.push({s: cloud4, r: 0.15});
    this.clouds.push({s: cloud5, r: 0.15});
    this.clouds.push({s: cloud6, r: 0.15});

    for (let i = 0; i < 10; i++) {
      const x = 900;
      const y = 260;

      const image = this.add.image(x, y, 'particle');
      image.setScale(0.3, 0.3);
      image.setBlendMode(Phaser.BlendModes.OVERLAY);

      this.smokes.smokes1.push({s: image, r: 0.05 + Math.random() * 0.5});
    }
    for (let i = 0; i < 5; i++) {
      const x = 1240;
      const y = 370;

      const image = this.add.image(x, y, 'particle');
      image.setScale(0.3, 0.3);
      image.setBlendMode(Phaser.BlendModes.OVERLAY);

      this.smokes.smokes2.push({s: image, r: 0.05 + Math.random() * 0.5});
    }
    for (let i = 0; i < 5; i++) {
      const x = 1920;
      const y = 400;

      const image = this.add.image(x, y, 'particle');
      image.setScale(0.5, 0.5);
      image.setBlendMode(Phaser.BlendModes.OVERLAY);

      this.smokes.smokes3.push({s: image, r: 0.05 + Math.random() * 0.5});
    }
    //==============END Particle==============//
    let ww = window.innerWidth;
    let wh = window.innerHeight;
    cam.setBounds(0, 0, 2000, 1125);
    let minZoom = Math.max(1.0 * ww / 2000, 1.0 * wh / 1125);
    Pubsub.subscribe(PUBSUB_WINDOW_RESIZE, () => {
      let newMinZoom = Math.max(1.0 * ww / 2000, 1.0 * wh / 1125);
      cam.setZoom(cam.zoom * newMinZoom / minZoom);
      minZoom = newMinZoom;
      if (cam.zoom < minZoom){
        cam.setZoom(minZoom);
      }
      if (cam.zoom > minZoom * 2){
        cam.setZoom(2 * minZoom)
      }
    })

    cam.setZoom(minZoom);
    this.input.on('pointermove', function (p: any, g: any) {
      if (!p.isDown) return;
      //console.log(p,g);
      cam.scrollX -= (p.x - p.prevPosition.x) / cam.zoom;
      cam.scrollY -= (p.y - p.prevPosition.y) / cam.zoom;
    });
    this.input.on(
      'wheel',
      (
        pointer: any,
        gameObjects: any,
        deltaX: any,
        deltaY: any,
        deltaZ: any
      ) => {
        if (deltaY > 0){
          let newZoom = this.cameras.main.zoom / ZOOM_SCALE;
          if (newZoom > minZoom){
            this.cameras.main.setZoom(newZoom);
          } else{
            this.cameras.main.setZoom(minZoom);
          }
        }

        if (deltaY < 0){
          let newZoom = this.cameras.main.zoom * ZOOM_SCALE;
          if (newZoom < 2 * minZoom){
            this.cameras.main.setZoom(newZoom);
          } else{
            this.cameras.main.setZoom(2 * minZoom);
          }
        }

        // this.camera.centerOn(pointer.worldX, pointer.worldY);
        // this.camera.pan(pointer.worldX, pointer.worldY, 2000, "Power2");
      }
    );
    let _this = this;
  }

  initGlow(img: Phaser.GameObjects.Image) {
    img.setBlendMode(Phaser.BlendModes.SCREEN)
      .setInteractive(this.input.makePixelPerfect(0.1))
      .setAlpha(0, 0, 0, 0)
    return img;
  }

  addGlow(overlayImg: Phaser.GameObjects.Image, href: string | any, scene: any, textOverlay?: Phaser.GameObjects.Text, alpha: number = 1) {
    this.imgOverlays.push(overlayImg);
    if (textOverlay) this.textOverlays.push(textOverlay);
    var postFxPlugin: any = this.plugins.get('rexglowfilter2pipelineplugin');
    var glowConfig = {
      distance: 10,

      outerStrength: 2,
      innerStrength: 0,
      glowColor: 0xdfec7c,
    };
    let thisScene = this;
    overlayImg.on('pointerover', function (p: any, g: any) {
      if (thisScene.phaserIn){
        overlayImg.setAlpha(alpha, alpha, alpha, alpha);
        textOverlay?.setAlpha(1);
      }
    });

    overlayImg.on('pointerout', function (p: any, g: any) {
      if (thisScene.phaserIn){
        overlayImg.setAlpha(0, 0, 0, 0);
        textOverlay?.setAlpha(isMobile?1:0);
      }
    });

    overlayImg.on('pointerup', (p: any, g: any) => {
      let {downX, downY, upX, upY} = p;
      let isDrag = (downX - upX) ** 2 + (downY - upY) ** 2 > 33;
      console.log(isDrag);
      if (!isDrag && thisScene.phaserIn){
        if (href.content){
          console.log('PUBSUB_COMING_SOON_OPEN')
          Pubsub.publish(PUBSUB_COMING_SOON_OPEN, href);
        } else{
          console.log('PUBSUB_NAVIGATE_TO')
          Pubsub.publish(PUBSUB_NAVIGATE_TO, href)
        }
      }
    });
  }

  update() {
   

    this.tick++;
    if (this.runeCircle){
      this.runeCircle.update();
    }
    if (this.towerLights){
      this.towerLights[0].update();
      this.towerLights[1].update();
    }

    for (let i = 0; i < this.smokes.smokes1.length; i++) {
      const sprite = this.smokes.smokes1[i].s;

      sprite.y -= this.smokes.smokes1[i].r;
      sprite.x += this.smokes.smokes1[i].r * 1.3;
      sprite.setScale(sprite.scaleX + this.smokes.smokes1[i].r * 0.01, sprite.scaleY + this.smokes.smokes1[i].r * 0.01);
      sprite.alpha -= this.smokes.smokes1[i].r * 0.003;
      if (sprite.y < -100){
        sprite.alpha = 1;
        sprite.setScale(0.3, 0.3);
        sprite.y = 260;
        sprite.x = 900;
      }
    }
    for (let i = 0; i < this.smokes.smokes2.length; i++) {
      const sprite = this.smokes.smokes2[i].s;

      sprite.y -= this.smokes.smokes2[i].r;
      sprite.x += this.smokes.smokes2[i].r * 1.5;
      //console.log((sprite.scaleX));
      sprite.setScale(sprite.scaleX + this.smokes.smokes2[i].r * 0.01, sprite.scaleY + this.smokes.smokes2[i].r * 0.01);
      sprite.alpha -= this.smokes.smokes2[i].r * 0.002;
      if (sprite.y < -100){
        sprite.alpha = 1;
        sprite.setScale(0.3, 0.3);
        sprite.y = 370;
        sprite.x = 1240;
      }
    }
    for (let i = 0; i < this.smokes.smokes3.length; i++) {
      const sprite = this.smokes.smokes3[i].s;

      sprite.y -= this.smokes.smokes3[i].r;
      sprite.x -= this.smokes.smokes3[i].r * 0.15;
      //console.log((sprite.scaleX));
      sprite.setScale(sprite.scaleX + this.smokes.smokes3[i].r * 0.01, sprite.scaleY + this.smokes.smokes3[i].r * 0.01);
      sprite.alpha -= this.smokes.smokes3[i].r * 0.003;
      if (sprite.y < -100){
        sprite.alpha = 1;
        sprite.setScale(0.5, 0.5);
        sprite.y = 400;
        sprite.x = 1920;
      }
    }
    for (let i = 0; i < this.clouds.length; i++) {
      const sprite = this.clouds[i].s;
      sprite.x += this.clouds[i].r * 1.3;


      if (sprite.x > 1900){
        sprite.x = 700;
      }
    }
  }
}


class TowerLight {
  private image: Phaser.GameObjects.Image;
  private maxScale: number = 0.3 + (Math.random() * .5);
  private currentScale: number = 0;
  private scaleDelta: number = 0.01;
  private angle = Math.random() * 360;
  private angleDelta = Math.random() > .5 ? 1 : -1;

  constructor(image: Phaser.GameObjects.Image) {
    this.image = image;
  }

  public update() {
    this.currentScale += this.scaleDelta;
    this.angle += this.angleDelta;

    if (this.currentScale > this.maxScale){
      this.scaleDelta = -this.scaleDelta;
    }
    if (this.currentScale <= 0){
      this.maxScale = 0.3 + (Math.random() * .7);
      this.currentScale = 0;
      this.scaleDelta = -this.scaleDelta;
      this.angle = Math.random() * 360;
      this.angleDelta = Math.random() > .5 ? 1 : -1;
    }
    this.image.setScale(this.currentScale).setAngle(this.angle);
  }
}

class RuneCircle {
  private image: Phaser.GameObjects.Image;
  private angle: number = 0;
  private angleDelta: number = .5;

  constructor(image: Phaser.GameObjects.Image) {
    this.image = image;
  }

  public update() {
    this.angle += this.angleDelta
    this.image.setAngle(this.angle);
  }
}