import * as React from 'react';
import { ReactNode } from 'react';
import './CenterContainer.scss';
import AnimatedContainer from './AnimatedContainer';
import classNames from 'classnames';

interface AnimatedContainerProps extends React.HTMLAttributes <HTMLDivElement> {
  isVisible: boolean,
  children?: ReactNode;
  width?: number | string;
  height?: number | string;
  scroll?: 'scroll'
}

const CenterContainer = ({
                           height,
                           width,
                           className,
                           isVisible,
                           children,
                           style,
                           scroll,
                           ...props
                         }: AnimatedContainerProps) => {
  if (!style) style = {};
  if (width){
    style.width = width;
  }
  if (height){
    style.height = height;
  }

  return <AnimatedContainer className={classNames('center-container', scroll, className)} animationOut={'fadeOut'}
                            animationIn={'fadeIn'}
                            isVisible={isVisible} {...props}>
    <div className={'center-inner-container'} style={style}>{children}</div>
  </AnimatedContainer>
};

export default CenterContainer;