import React from 'react';
import ReactDOM from 'react-dom';
import { App } from 'App';
import { Application } from './components/Application';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store, persistor } from './app/store';
import './animate.min.css';
import { PersistGate } from 'redux-persist/integration/react';
import './index.scss';
import './theme/font.scss';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Application>
          <App/>
          {/*<ReCAPTCHA*/}
          {/*  sitekey="6LcZTvQiAAAAACEmZgB8uhKsF6Mw0hJOt2A2VHbG"*/}
          {/*  size={'compact'}*/}
          {/*  onChange={(a) => console.log(a)}*/}
          {/*/>*/}
        </Application>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
