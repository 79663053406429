import CenterContainer from '../Layout/CenterContainer';
import React from 'react';
import OmsTitle from '../CustomComponent/OmsTitle';
import ScrollContainer from '../Layout/ScrollContainer';
import './TermsAndConditionsPage.scss';
import TermsAndConditionsContent from './TermsAndConditionsContent';

const TermsAndConditionsPage = () => {
  return <CenterContainer isVisible={true} width={1700} scroll={'scroll'} height={'90vh'}
                          className={'terms-and-conditions'}>
    <OmsTitle level={2} textAlign={'center'} textColor={'text-color-white'}>One Studios Privacy Policy</OmsTitle>
    <ScrollContainer height={'80vh'} style={{padding: '0 20px 0 20px'}}>
      <TermsAndConditionsContent/>
    </ScrollContainer>
  </CenterContainer>
}

export default TermsAndConditionsPage;