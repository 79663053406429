import { HTMLAttributes } from 'react';
import classNames from 'classnames';
import './NotifyForm.scss';
import { CheckCircleFilled } from '@ant-design/icons';

interface NotifyFormProps extends HTMLAttributes<HTMLDivElement> {
  icon: 'success' | 'failed' | undefined;
}

export default (_props: NotifyFormProps) => {
  const {icon, className, children, ...props} = _props;
  return <div className={classNames('notify-form', className)} {...props}>
    {icon === 'success' && <CheckCircleFilled/>}
    {children}
  </div>
}