import React, {useEffect, useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {Button, Modal, notification, Typography, Input, Col, Row, Form, Select} from 'antd';
import {CountryList} from 'helpers/data/country';
import Pubsub from 'pubsub-js';
import {
  ActiveNetworks,
  NFTContractList,
  API_NFT_GETPACKAGES,
  API_ORDER_APPLYCOUPON,
  API_ORDER_BUYPACKAGEWITHPAYPAL,
  API_ORDER_CAPTUREPAYPAL,
  API_ORDER_SALEINFO,
  API_ORDER_UPDATEORDERTX,
  API_ORDER_WHITELISTINFO, EXPLORER_URL, API_NFT_UPDATEBLOCK, API_NFT_BUYPACKAGEWITHCOIN,
} from 'helpers/config';
import {useAppSelector} from '../app/hooks';
import {userInfoSelector} from './UserForm/UseSlice';
import CenterContainer from './Layout/CenterContainer';
import './MintWithPackages.scss';
import * as CommonHelper from 'helpers/common';
import {PayPalButtons, PayPalScriptProvider} from '@paypal/react-paypal-js';
import {useOmsWeb3} from '../hooks/UseOmsWeb3';

var hash = require('object-hash');

const {Title} = Typography;

function Mint() {
  const navigate = useNavigate();
  const {mint, walletOfOwner} = useOmsWeb3();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({
    totalSupply: 0,
    maxSupply: 50,
    price: 0,
    maxMintAmount: 1,
  });

  const [mintAmount, setMintAmount] = useState(1);
  const userInfo = useAppSelector(userInfoSelector);
  const [packages, setPackages] = useState([]);
  const [whitelist, setWhitelist] = useState([]);
  const [selectPackage, setSelectPackage] = useState(-1);
  const [orderForm, setOrderForm] = useState({
    coupon: '',
    name: userInfo.firstName,
    lname: userInfo.lastName,
    address: '',
    phone: '',
    address2: '',
    city: '',
    state: '',
    postal_code: '',
    country: 'VN',
  });
  const [errorFields, setErrorFields] = useState([])
  const [form] = Form.useForm();
  const [coupon, setCoupon] = useState(null);
  const mintAble = true;
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    onValuesChange();
  }, [selectPackage]);

  const checkNftOwners = (blockNumber, oldIds, signId, onSuccess) => {
    let loading = false;
    let retryCount = 0;
    const intervalId = setInterval(async () => {
      if (loading) return;
      loading = true;
      const result = (await walletOfOwner()).sort((a, b) => a - b);
      const _oldIds = oldIds?.sort((a, b) => a - b);
      // check result is same as oldIds


      if (oldIds?.length !== result?.length || !result.every((v, i) => v === _oldIds[i])) {
        console.log('Block process successfully.');
        clearInterval(intervalId);
        setTimeout(() => {
          fetch(API_NFT_UPDATEBLOCK, {
            method: 'POST',
            body: JSON.stringify({'startBlock': blockNumber, 'signId':signId, 'whitelist':whitelist}),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            mode: 'cors'
          }).then(() => onSuccess());
        }, 5000);
      } else if (retryCount >= 9) {
        console.log('Block till pending');
        clearInterval(intervalId);
      } else {
        retryCount++;
      }
      loading = false;
    }, 5000);
  }

  const loadData = async () => {
    setLoading(true);
    try {
      let rel = await CommonHelper.postJSON(API_ORDER_SALEINFO);
      // set price to state
      if (rel && rel.data) {
        setData((info) => ({
          ...info,
          maxSupply: rel.data.max_token,
          totalSupply: rel.data.minting + rel.data.minted,
        }));
      }
      rel = await CommonHelper.postJSON(API_NFT_GETPACKAGES, {type: 'NFT'});
      // set price to state
      if (rel && rel.data) {
        setPackages(rel.data);
      }
      rel = await CommonHelper.postJSON(API_ORDER_WHITELISTINFO, {});
      // set price to state
      if (rel && rel.data) {
        setWhitelist(rel.data);
      }

    } catch (e) {
      console.log(e);
      // notification.error({
      //   message: e.message,
      // });
    }
    setLoading(false);
    setLoaded(true);
  };
  const mintNFT = async () => {
    console.log('Minting...');
    const old_ids = await walletOfOwner();
    let p = packages[selectPackage];
    let postOptions = {packageId: p._id, coupon: coupon?.coupon_code};
    let rel = await CommonHelper.postJSON(API_NFT_BUYPACKAGEWITHCOIN, postOptions);
    const topic = mint({rel});
    Pubsub.subscribe(topic, (msg, res) => {
      if (res.data) {
        const tx = res.data;
        console.log(tx);
        notification.success({
          message: 'Congratulations! OMS Token is yours! Tx: ' + tx.transactionHash,
        });
        checkNftOwners(tx.blockNumber, old_ids, rel.signId, () => {
          console.log('Block process successfully.');
        });
      }
      if (res.error) {
        console.log('Caught an error');
        notification.error({ 
          message: res.error.data.message 
        });
      }
      loadData();
    });
  };
  const updateForm = (key, value) => {
    setOrderForm((info) => ({...info, [key]: value}));
  }

  const onValuesChange = (changedValues, allValues) => {

    form
      .validateFields()
      .then((values) => {
        setOrderForm((info) => ({
          ...info,
          name: values.firstName,
          lname: values.lastName,
          phone: values.phone,
          address: values.address,
          address2: values.address2,
          city: values.city,
          state: values.state,
          postal_code: values.postal_code
        }));
        console.log(values);
      })
      .catch((errorInfo) => {
        setErrorFields(errorInfo.errorFields);
        let values = errorInfo.values;
        setOrderForm((info) => ({
          ...info,
          name: values.firstName,
          lname: values.lastName,
          phone: values.phone,
          address: values.address,
          address2: values.address2,
          city: values.city,
          state: values.state,
          postal_code: values.postal_code
        }));

      });

  }
  const applyCoupon = async () => {
    if (!orderForm.coupon || orderForm.coupon.trim().length == 0) {
      notification.error({
        message: 'Please enter coupon code',
      });
      return;
    }
    let rel = await CommonHelper.postJSON(API_ORDER_APPLYCOUPON, {coupon: orderForm.coupon});
    if (rel && rel.coupon_code) {
      setCoupon(rel);
      notification.success({
        message: 'Coupon applied',
      });
    } else {
      notification.error({
        message: rel.error || 'Apply coupon failed',
      });
    }
  }
  const getPaypalButtons = (pkg, orderForm, coupon, whitelist) => {

    return pkg && <PayPalButtons
      key={hash({pkg_id: pkg._id, ...coupon, ...orderForm, whitelist})}

      style={{'layout': 'vertical', 'label': 'buynow'}}
      createOrder={async (data, actions) => {
        let p = pkg
        let postOptions = {packageId: p._id, coupon: coupon?.coupon_code};
        if (p.request_info) {
          postOptions = {...postOptions, ...orderForm, coupon: coupon?.coupon_code};
        }
        let rel = await CommonHelper.postJSON(API_ORDER_BUYPACKAGEWITHPAYPAL, postOptions);

        if (rel && rel._id) {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: rel.total // Can also reference a variable or function
              },
              custom_id: rel._id
            }]
          });
          ;
        } else {
          notification.error({
            message: rel.error || 'Create order failed',
          });
        }

      }}
      onCancel={async (data, actions) => {
        notification.error({
          message: 'Cancelled',
        });
        setCoupon(null);
      }}
      onError={async (data, actions) => {
        notification.error({
          message: 'Order Failed',
        });
        setCoupon(null);
      }}


      onApprove={async (data, actions) => {
        //console.log(data,actions)
        let order = await actions.order.get();
        //console.log(order,data.orderID,order.purchase_units[0].custom_id);

        let rel = await CommonHelper.postJSON(API_ORDER_CAPTUREPAYPAL, {
          paypalOrderId: data.orderID,
          orderId: order.purchase_units[0].custom_id
        });
        if (rel && rel.orderId) {
          notification.success({
            message: 'Your purchase is complete, please wait 5 - 10 minutes for your NFT to arrive.',
          });
          loadData()
          return true;
        } else {
          notification.error({
            message: rel.error || 'Capture failed',
          });
        }
        return false;
      }}
    /> || <></>
  }
  if (!userInfo.uid) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
      />
    );
  }
  return (
    <CenterContainer width={800} className={'mint-container'}>
      <PayPalScriptProvider
        options={{
          'client-id': 'AeK3EXeRc2U8-k7V8wyq4L7OIY_pTsemPaZFSWAYWRYePjR30qcah0-uME3o1OdcBGes8ty3G6-aXBCc',
          components: 'buttons',
        }}
        fundingSource="paypal"
      >
        {!mintAble && <div>
          <Title level={2}>Attention!</Title>
          <Title level={3} style={{color: '#bbb'}}>Our DAPP minting function is temporarily paused for a
            couple of days as we are launching on Gate.IO.</Title>
          <Title level={3} style={{color: '#bbb'}}> Check it out!</Title>
        </div>}

        {mintAble && <div>
          <Title level={3}>Magic Disciples Minted:</Title>
          <p className={'supply-number'} style={{fontSize: 34, color: '#888', marginBottom: 20}}>Batch 1: 50/50 (Sold
            Out)</p>
          <Title level={2} style={{color: '#F9961D'}}>Batch
            2: {data.totalSupply == 0 ? '???' : data.totalSupply} /{' '}{data.maxSupply} (On going)</Title>
          {loaded && userInfo && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {Number(data.totalSupply) >= Number(data.maxSupply) ? (
                <div>
                  <p style={{textAlign: 'center', fontSize: 18}}>SOLD OUT</p>
                  <div className={'sale-info'}>
                    <p className={'grey'} style={{textAlign: 'center', fontSize: 18}}>Batch 1: 50 NFT, $22 (Discount
                      78%, Open on Nov 17) (Sold Out)</p>
                    <p className={'highlight'} style={{textAlign: 'center', fontSize: 18}}>Batch 2: 450 NFT, $45
                      (Discount 55%, Open on Dec 12)</p>
                    <p style={{textAlign: 'center', fontSize: 18}}>Batch 3: 800 NFT, $78 (Discount 12%, Open after batch
                      2)</p>
                    <p style={{textAlign: 'center', fontSize: 18}}>Batch 4: 1200 NFT, $99 (Open after batch 3)</p>
                  </div>
                </div>
              ) : (
                <div style={{textAlign: 'center'}}>
                  {userInfo.wallet ? (
                    <>
                      <div className={'sale-info'} style={{margin: '10px'}}>
                        <p className={'grey'} style={{textAlign: 'center', fontSize: 18}}>Batch 1: 50 NFT, $22 (Discount
                          78%, Open on Nov 17) (Sold Out)</p>
                        <p className={'highlight'} style={{textAlign: 'center', fontSize: 18}}>Batch 2: 450 NFT, $45
                          (Discount 55%, Open on Dec 12)</p>
                        <p style={{textAlign: 'center', fontSize: 18}}>Batch 3: 800 NFT, $78 (Discount 12%, Open after
                          batch 2)</p>
                        <p style={{textAlign: 'center', fontSize: 18}}>Batch 4: 1200 NFT, $99 (Open after batch 3)</p>
                      </div>
                      <Row>
                        {packages.map((item, index) => {
                          return (
                            <Col span={24 / packages.length} key={index} style={{padding: 5, display: 'flex'}}>
                              <div
                                className={selectPackage != index ? 'mint-package' : 'mint-package mint-package-selected'}
                                onClick={() => setSelectPackage(index)}>
                                <div className={'mint-package-title'}>
                                  {item.name}
                                </div>
                                <div className={'mint-package-description'}>
                                  You will receive:<br/>
                                  <b>{item.description}</b>
                                </div>
                                {selectPackage !== index && <div className={'mint-package-price-org'}>
                                  {item.price_coin_org} Matic/{item.price_usd_org} USD
                                </div>}
                                {selectPackage !== index && <div className={'mint-package-price'}>
                                  {item.price_coin} Matic/{item.price_usd} USD
                                </div>}
                                {selectPackage === index && <div>
                                  <div className={'mint-package-price-detail'}>
                                    <b>Normal Price:</b><br/>
                                    {item.price_coin_org} Matic/{item.price_usd_org} USD
                                  </div>
                                  <div className={'mint-package-price-detail'}>
                                    <b>Current Price:</b><br/>
                                    {item.price_coin} Matic/{item.price_usd} USD
                                  </div>
                                  {whitelist && whitelist.nft_qty > whitelist.nft_distributed && index <1 ?
                                    <div className={'mint-package-price-detail'}>
                                      <b>Discount:</b><br/>
                                      {item.price_coin} Matic
                                    </div> : coupon != null ? <div className={'mint-package-price-detail'}>
                                      <b>Discount:</b><br/>
                                      {(item.price_coin * coupon.value / 100).toFixed(2)} Matic
                                      / {(item.price_usd * coupon.value / 100).toFixed(2)} USD
                                    </div> : false}
                                  <div className={'mint-package-price-detail'}>
                                    <b>Final price:</b><br/>
                                    {whitelist && whitelist.nft_qty > whitelist.nft_distributed && index < 1 ? <>0
                                        Matic</> :
                                      coupon != null ? <>{(item.price_coin * (1 - coupon.value / 100)).toFixed(2)} Matic
                                          / {(item.price_usd * (1 - coupon.value / 100)).toFixed(2)} USD</>
                                        : <>{item.price_coin} Matic/{item.price_usd} USD</>}
                                  </div>
                                </div>}
                              </div>
                            </Col>
                          )
                        })}
                      </Row>

                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 17
                      }}>
                        {packages[selectPackage]?.request_info && <div style={{width: 320}}>
                          <Form className={'mint-address-form'} name={'userModel'} layout={'vertical'}
                                form={form} onFinish={(values) => console.log(values)} onValuesChange={onValuesChange}
                                initialValues={{firstName: userInfo.firstName, lastName: userInfo.lastName}}>
                            <Row gutter={[8, 0]}>
                              <Col sm={12} xs={24}>
                                <Form.Item name={'firstName'} label={'First name'}
                                           wrapperCol={{sm: 24, xs: 24}}
                                           rules={[{required: true, message: 'Please input your first name!'}]}
                                           validateTrigger="onChange">
                                  <Input placeholder={'First name'}/>
                                </Form.Item>
                              </Col>
                              <Col sm={12} xs={24}>
                                <Form.Item name={'lastName'} label={'Last name'}
                                           wrapperCol={{sm: 24, xs: 24}}
                                           rules={[{required: true, message: 'Please input your last name!'}]}
                                           validateTrigger="onChange">
                                  <Input placeholder={'Last name'}/>
                                </Form.Item>
                              </Col>
                              <Col sm={24} xs={24}>
                                <Form.Item name={'address'} label={'Address Line 1'}
                                           wrapperCol={{sm: 24, xs: 24}}
                                           rules={[{required: true, message: 'Please input Address Line 1!'}]}
                                           validateTrigger="onChange">
                                  <Input placeholder={'Address Line 1'}/>
                                </Form.Item>
                              </Col>
                              <Col sm={24} xs={24}>
                                <Form.Item name={'address2'} label={'Address Line 2'}
                                           wrapperCol={{sm: 24, xs: 24}}
                                           rules={[]}
                                           validateTrigger="onChange">
                                  <Input placeholder={'Address Line 2'}/>
                                </Form.Item>
                              </Col>
                              <Col sm={12} xs={24}>
                                <Form.Item name={'city'} label={'City'}
                                           wrapperCol={{sm: 24, xs: 24}}
                                           rules={[{required: true, message: 'Please input city!'}]}
                                           validateTrigger="onChange">
                                  <Input placeholder={'City'}/>
                                </Form.Item>
                              </Col>
                              <Col sm={12} xs={24}>
                                <Form.Item name={'state'} label={'State/Province'}
                                           wrapperCol={{sm: 24, xs: 24}}
                                           rules={[{required: true, message: 'Please input state/provine!'}]}
                                           validateTrigger="onChange">
                                  <Input placeholder={'State/Provine'}/>
                                </Form.Item>
                              </Col>
                              <Col sm={12} xs={24}>
                                <Form.Item name={'postal_code'} label={'Postal Code'}
                                           wrapperCol={{sm: 24, xs: 24}}
                                           rules={[{required: true, message: 'Please input postal code!'},
                                             {
                                               pattern: /^([0-9]*)+$/,
                                               message: 'Invalid postal code!'
                                             }]}
                                           validateTrigger="onChange">
                                  <Input placeholder={'Postal Code'}/>
                                </Form.Item>
                              </Col>
                              <Col sm={12} xs={24}>
                                <Form.Item name={'phone'} label={'Phone Number'}
                                           wrapperCol={{sm: 24, xs: 24}}
                                           rules={[{required: true, message: 'Please input your phone!'}, {
                                             pattern: /^([0-9]{6,12})$/,
                                             message: 'Invalid phone number!'
                                           }]}
                                           validateTrigger="onChange">
                                  <Input placeholder={'Phone'}/>
                                </Form.Item>
                              </Col>
                              <Col sm={12} xs={24}>
                                <Form.Item name={'country'} label={'Country'}
                                           wrapperCol={{sm: 24, xs: 24}}
                                           validateTrigger="onChange">
                                  <Select
                                    defaultValue="VN"
                                    style={{width: '100%', textAlign: 'center', marginBottom: 10}}
                                    onChange={(e) => updateForm('country', e)}
                                    options={CountryList}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                        }
                      </div>
                      {packages[selectPackage] && (!packages[selectPackage]?.request_info || (packages[selectPackage]?.request_info && errorFields?.length == 0)) && <>
                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: 10
                        }}>
                          {!coupon ? <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '70%'
                          }}>
                            <Input
                              autoFocus
                              onChange={(e) => updateForm('coupon', e.target.value)}
                              defaultValue={''}
                              placeholder={'Coupon Code'}
                              value={orderForm.coupon}
                              className=" no-spinner"
                              type="text"
                              autoComplete="off"
                              disabled={coupon != null}
                            />
                            <Button type="primary" size="medium" onClick={applyCoupon} style={{height: 32}}>
                              Apply
                            </Button>
                          </div> : <p style={{textAlign: 'center', fontSize: 18}}>Coupon
                            Applied: {coupon.coupon_code} - {coupon.value}% OFF </p>}
                        </div>
                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          marginTop: 17,
                          alignItems: 'center'
                        }}>
                          <Button type="primary" size="large" onClick={mintNFT}
                                  style={{height: 55, maxWidth: 750, minWidth: 320}}>
                            Buy with Matic
                          </Button>
                          <div style={{minWidth: '320px', marginTop: 17}}>
                            {getPaypalButtons(packages[selectPackage], orderForm, coupon, whitelist)}
                          </div>
                        </div>
                      </>}
                    </>
                  ) : (
                    <>
                      {' '}
                      {/* <p
                      style={{
                        textAlign: "center",
                        fontSize: 14,
                        color: COLORS.primary,
                      }}
                    >
                      Please connect a wallet.
                    </p> */}
                      <Button
                        type="secondary"
                        size="large"
                        onClick={() => navigate('/account')}
                      >
                        Link Your Wallet To Mint
                      </Button>
                    </>
                  )}
                </div>
              )}
              {userInfo.wallet && (
                <a
                  style={{
                    marginTop: 20,
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href={EXPLORER_URL + userInfo.wallet}
                >
                  Your wallet: {userInfo.wallet}
                </a>
              )}

              <a
                style={{
                  marginTop: 10,
                }}
                target="_blank"
                rel="noreferrer"
                href={
                  EXPLORER_URL + 'address/' +
                  NFTContractList[ActiveNetworks.polygon]
                }
              >
                Contract address: {NFTContractList[ActiveNetworks.polygon]}
              </a>
            </div>
          )}
          <Modal className="modalLoading" visible={loading} footer={null}>
            <div>
              <center className="loadingCenter">
                <img src="./loading_shard.gif"></img>{' '}
              </center>
            </div>
          </Modal>
        </div>}
      </PayPalScriptProvider>
    </CenterContainer>
  );
}

export default Mint;
