import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

type AppStatus = 'loading' | 'ready';

interface AppState {
  status: AppStatus,
  welcome: boolean,
  web3loading: boolean,
  apiLoading: { [key: string]: boolean }
}

const initialState: AppState = {
  status: 'ready',
  welcome: false,
  web3loading: false,
  apiLoading: {}
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    'addApiLoading': (state, action: PayloadAction<string>) => {
      state.apiLoading[action.payload] = true;
    },
    'removeApiLoading': (state, action: PayloadAction<string>) => {
      delete state.apiLoading[action.payload];
    },
    'updateAppStatus': (state, action: PayloadAction<AppStatus>) => {
      state.status = action.payload
    },
    'updateAppWelcome': (state, action: PayloadAction<boolean>) => {
      state.welcome = action.payload
    },
    'updateWeb3Loading': (state, action: PayloadAction<boolean>) => {
      state.web3loading = action.payload
    }
  }
});

export default appSlice.reducer;
export const {updateAppStatus, updateAppWelcome, updateWeb3Loading, addApiLoading, removeApiLoading} = appSlice.actions;
export const appStatusSelector = (state: RootState) => state.app.status;
export const appWelcomeSelector = (state: RootState) => state.app.welcome;
export const web3LoadingSelector = (state: RootState) => state.app.web3loading;
export const apiLoadingSelector = (state: RootState) => state.app.apiLoading;