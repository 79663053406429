import { Button, Col, Divider, Form, FormProps, Input, Modal, Row, Typography } from 'antd';
import classNames from 'classnames';
import { changeStep, resetUserFormRequestStatus, signUp, userFormErrorSelector } from './UseSlice';
import './SignupForm.scss';
import LoginFormContainer from './LoginFormContainer';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useEffect, useState } from 'react';
import TermsAndConditionsContent from '../Pages/TermsAndConditionsContent';
import { Link, useSearchParams } from 'react-router-dom';
import OmsCheckbox from '../CustomComponent/OmsCheckbox';
import { FieldData } from 'rc-field-form/es/interface';

const {Password} = Input;
const {Title} = Typography;

export default (props: FormProps) => {
  const [searchParams,] = useSearchParams();
  const dispatch = useAppDispatch();
  const [showPrivacy, setShowPrivacy] = useState<boolean>(false);
  const refID = searchParams.get('refID');
  const formErrors = useAppSelector(userFormErrorSelector);
  const clickLoginLink = () => {
    dispatch(changeStep('login'));
  };
  const [form] = Form.useForm();
  useEffect(()=>{dispatch(resetUserFormRequestStatus)},[]);
  useEffect(() => {
    let fields: FieldData[] = [];
    if (Object.keys(formErrors || []).length > 0){
      for (let key in formErrors) {
        let fieldData: FieldData = {
          name: key, errors: formErrors[key]
        }
        fields = fields.concat(fieldData);
      }
      form.setFields(fields);
    }
  }, [formErrors]);
  return <LoginFormContainer step={'register'}>
    <Form className={classNames('register-form', props.className)} name={'userModel'} layout={'vertical'}
          form={form} onFinish={(values) => dispatch(signUp(values))} initialValues={{refID: refID}}>
      <Title level={1}>Register</Title>
      <Row gutter={[8, 0]}>
        <Col sm={24} xs={24}>
          <Form.Item name={'email'} label={'Email'}
                     wrapperCol={{sm: 24, xs: 24}}
                     rules={[{required: true, message: 'Please input your email!'}, {
                       type: 'email',
                       message: 'The input is not valid E-mail!'
                     }]}>
            <Input placeholder={'Email'}/>
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item name={'password'} label={'Password'}
                     wrapperCol={{sm: 24, xs: 24}}
                     rules={[{required: true, message: 'Please input your password!'},
                       {
                         min: 6,
                         message: 'Password min length is 6 characters'
                       },
                       {
                         max: 70,
                         message: 'Password too long'
                       },
                       {
                         pattern: /^(?=.*?[A-Z]).+$/,
                         message: 'Password must have at least one Uppercase'
                       },
                       {
                         pattern: /^(?=.*?[a-z]).+$/,
                         message: 'Password must have at least one lowercase'
                       },
                       {
                         pattern: /^(?=.*?[0-9]).+$/,
                         message: 'Password must have at least one numeric'
                       }, {
                         pattern: /^[A-Za-z0-9!@#$%^&*]+$/,
                         message: 'Password allow UPPERCASE, lowercase, numeric and special character !@#$%^&*'
                       },
                       {
                         validator: async (rule, value) => {
                           const repeatPassword = form.getFieldValue('repeatPassword');
                           if (!repeatPassword){
                             return;
                           }
                           if (value !== repeatPassword){
                             throw new Error('Password and Repeat Password not match');
                           }
                         },
                         message: 'Password and Repeat Password not match'
                       }]}>
            <Password placeholder={'Password'} key={'password'}
                      onChange={() => form.getFieldValue('repeatPassword') && form.validateFields(['repeatPassword'])}/>
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item name={'repeatPassword'} label={'Repeat Password'}
                     wrapperCol={{sm: 24, xs: 24}}
                     rules={[{required: true, message: 'Please input your password again!'},
                       {
                         validator: async (rule, value) => {
                           const password = form.getFieldValue('password');
                           if (!password){
                             return;
                           }
                           if (value !== password){
                             throw new Error('Password and Repeat Password not match');
                           }
                         },
                         message: 'Password and Repeat Password not match'
                       }]}>
            <Password placeholder={'Password'}
                      onChange={() => form.validateFields(['password'])}/>
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item name={'firstName'} label={'First name'}
                     wrapperCol={{sm: 24, xs: 24}}
                     rules={[{required: true, message: 'Please input your first name!'}]}>
            <Input placeholder={'First name'}/>
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item name={'lastName'} label={'Last name'}
                     wrapperCol={{sm: 24, xs: 24}}
                     rules={[{required: true, message: 'Please input your last name!'}]}>
            <Input placeholder={'Last name'}/>
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item name={'refID'} label={'Referral ID'}>
            <Input placeholder={'Referral ID'}/>
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item name={'agreeTermsAndConditions'} valuePropName="checked" rules={[{
            required: true, message: 'Please agree with Terms and Conditions', pattern: /^true$/
          }]}>
            <OmsCheckbox textColor={'text-color-white'}>I have read and agree to the  <Link
              to={'#'}
              onClick={() => setShowPrivacy(true)}>Terms
              and Conditions</Link>.<br />I agree to receive news, announcement, and promotional contents from One Magic School via email.</OmsCheckbox>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item className={'full-width'}>
        <Button type={'primary'} htmlType="submit" size={'large'}>Register</Button>
      </Form.Item>
      <Divider></Divider>
      <Form.Item>
        Already have account? <a onClick={clickLoginLink}>Login now!</a>
      </Form.Item>
      <Modal title={'One Studios Privacy Policy'} open={showPrivacy} onOk={() => setShowPrivacy(false)} width={1700}
             footer={[<Button onClick={() => setShowPrivacy(false)}>Confirm</Button>]} closable={false}>
        <TermsAndConditionsContent/>
      </Modal>
    </Form>
  </LoginFormContainer>;
};
