import { Button } from 'antd';
import React from 'react';
import Icon from '@ant-design/icons';
import { WalletConnectIcon } from '../Icons/index';
import { useOmsWeb3 } from '../../hooks/UseOmsWeb3';

interface WalletInjectLoginProps {
  onSuccess?: (flatSig: string) => void;
  type?: 'dashed' | 'link';
}

export default (_prop: WalletInjectLoginProps) => {
  const {onSuccess, type} = _prop;
  const {connect} = useOmsWeb3();

  const linkWallet = async () => {
    console.log('Connect by walletconnect');
    const topic = connect('walletconnect');
      PubSub.subscribe(topic, (msg, res) => {
        if (res?.data) {
        console.log('flatSig', res?.data);
        onSuccess?.(res?.data.toString());
      }
      PubSub.unsubscribe(topic);
    });

  };

  const style = type === 'link' ? {} : {width: '100%'}

  return <>
    <Button icon={<Icon component={WalletConnectIcon}/>} type={type === 'link' && 'link' || 'dashed'} style={style}
            onClick={linkWallet}
            size={'large'}>WalletConnect</Button>
  </>
}
