import LoginFormContainer from './LoginFormContainer';
import classNames from 'classnames';
import { Form, Input, Typography } from 'antd';
import React from 'react';

const {Title} = Typography;

const EmailVerifyForm = () => {
  const onSubmitEmail = ({email}: { email: string }) => {
    console.log(email);
  }

  return <LoginFormContainer step={'verify-email'}>
    <Form className={classNames('email-verify-form')} name={'userModel'} layout={'vertical'}
          onFinish={onSubmitEmail}>
      <Title level={1}>Sign in</Title>
      <Form.Item name={'email'}
                 wrapperCol={{sm: 24, xs: 24}}
                 rules={[{required: true, message: 'Please input your email!'}]}>
        <Input placeholder={'Email'} key={'email'}/>
      </Form.Item>
    </Form>
  </LoginFormContainer>
};

export default EmailVerifyForm;