import * as CommonHelper from '../helpers/common';
import { useAppDispatch } from '../app/hooks';
import { addApiLoading, removeApiLoading } from '../app/AppSlice';

interface PostJson {

}

export const usePostJson = () => {
  const dispatch = useAppDispatch();
  const postJson = async (url: string, data: any): Promise<any> => {
    const apiKey = `${url}-${new Date().getTime()}`
    dispatch(addApiLoading(apiKey));
    return new Promise((resolve, reject) => {
      CommonHelper.postJSON(url, data).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      }).finally(() => {
        dispatch(removeApiLoading(apiKey));
      });
    });
  }

  return postJson;
}