import { configureStore } from '@reduxjs/toolkit';
import accountReducer from '../components/UserForm/UseSlice';
import nftBalanceReducer from '../components/NFTBalance/NFTBalanceSlice';
import appReducer from './AppSlice';
import mobileMenuReducer from '../components/MobileMenu/MobileMenuSlice';
import soundReducer from '../components/SoundControl/SoundSlice';
import homePhaserReducer from '../components/HomePhaser/HomePhaserSlice';
import appConfigReducer from './AppConfigSlice';
import { persistStore } from 'redux-persist';
import { asyncDispatchMiddleware } from './AsyncDispatchMiddleWare';
import shardReducer from '../components/Shard/ShardSlice';
import schoolModulesReducer from '../components/SchoolModule/SchoolModuleSlice';
import gameUserInfoReducer from 'components/SchoolModule/gameUserInfoSlide';
import characterInfoReducer from 'components/SchoolModule/CharacterSlide';
import gameResourceInfoReducer from 'components/SchoolModule/ResourceSlide';


export const store = configureStore({
  reducer: {
    account: accountReducer,
    nftBalance: nftBalanceReducer,
    app: appReducer,
    mobileMenu: mobileMenuReducer,
    homePhaser: homePhaserReducer,
    appConfig: appConfigReducer,
    shard: shardReducer,
    sound: soundReducer,
    schoolModules: schoolModulesReducer,
    gameUserInfo: gameUserInfoReducer,
    characterInfo: characterInfoReducer,
    gameResourceInfo: gameResourceInfoReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    thunk: true,
    serializableCheck: false
  }).concat(asyncDispatchMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store)
