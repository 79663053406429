import * as React from 'react';
import classNames from 'classnames';
import { TextProps } from 'antd/lib/typography/Text';
import { TextAlignType, TextColorType } from './Types';


interface OmsLabelProps extends TextProps {
  textType: 'label' | 'text' | 'text-value';
  textAlign?: TextAlignType;
  widthMode?: 'full-width' | 'auto-width';
  textColor?: TextColorType;
}

const OmsText = (props: OmsLabelProps) => {
  const {textType, textAlign, widthMode, textColor, ..._props} = props;
  return <div
    className={classNames('oms-text', widthMode, textAlign, textType, textColor, props.className)} {..._props}></div>
}

export default OmsText;