import { Typography } from 'antd';
import * as React from 'react';
import { TextProps as _TextProps } from 'antd/lib/typography/Text';
import './Text.scss';

const {Text: _Text} = Typography;


interface TextProps extends _TextProps {
  fontSize?: 'lg' | 'md' | 'sm' | number;
  children?: React.ReactNode | string;
}

const Text = (props: TextProps) => {
  let extProps: any = {}
  if (typeof props.fontSize === 'number'){
    extProps.style = {fontSize: props.fontSize}
  } else{
    extProps.className = 'font-size-' + (props.fontSize ? props.fontSize : 'md');
  }
  return <_Text {...extProps} {...props} />;
}
export default Text;