import {useState,useEffect} from 'react';



const styles = {
  root: {

    position: 'absolute',
    bottom: '5rem',
    right: '3rem',
    zIndex: 20,
    width:30,
    height: 30,
    cursor: 'pointer'

  },
  icon: {
    width:60,
    height: 60
  },
} as const;
interface SoundControlProps{
  sound:string;
}
function SoundControl(props:SoundControlProps) {
  const [audio] = useState(new Audio(props.sound));
  const [playing, setPlaying] = useState(false);
  const [icon] = useState({on:'/sound/music_on.png',off:'/sound/music_off.png'});
  useEffect(() => {
    audio.volume = 0.5;
    audio.loop = true;
    setPlaying(true);
    return () => { audio.pause(); };
  }, []);
  useEffect(() => {
    if(playing)
    {
      audio.play();
    }
    else {
      audio.pause();
    }
    
  }, [playing]);
  return (
    <>
      <div
        style={styles.root}
      >
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} onClick={()=>setPlaying(!playing)} >
         <img src={playing?icon.on:icon.off} style={styles.icon}  />
          
        </div>
      </div>
    </>
  );
}


export default SoundControl;
