import { Input, InputProps } from 'antd';
import classNames from 'classnames';

interface OmsInputProps extends InputProps {
  widthMode?: 'full-width' | 'auto-width';
}

const OmsInput = (props: OmsInputProps) => {
  const {widthMode, className, ..._props} = props
  return <Input className={classNames('oms-input', widthMode, className)}{..._props} />
}

export default OmsInput;