import * as React from 'react';
import classNames from 'classnames';
import './ScrollContainer.scss';

interface ScrollContainerProps extends React.HTMLAttributes <HTMLDivElement> {
  height?: number | string;
}

const ScrollContainer = (_props: ScrollContainerProps) => {
  const {className, style, height, ...props} = _props;
  return <div className={classNames('scroll-container', className)} style={{height, ...style}} {...props}></div>
}

export default ScrollContainer;