import { Alert, Button, Col, Divider, Form, FormProps, Input, Modal, notification, Row, Typography } from 'antd';
import classNames from 'classnames';
import {
  changeStep,
  googleTokenSelector,
  userFormErrorSelector, userInfoSelector, signupGoogle, walletSignSelector, signupWallet
} from './UseSlice';
import LoginFormContainer from './LoginFormContainer';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useEffect, useState } from 'react';
import { FieldData } from 'rc-field-form/es/interface';
import OmsCheckbox from '../CustomComponent/OmsCheckbox';
import { Link, useSearchParams } from 'react-router-dom';
import TermsAndConditionsContent from '../Pages/TermsAndConditionsContent';
import { usePostJson } from '../../hooks/UsePostJson';
import { API_SEND_OTP_EMAIL } from '../../helpers/config';

const {Title} = Typography;

export default (props: FormProps) => {
  const [searchParams,] = useSearchParams();
  const refID = searchParams.get('refID');
  const userInfo = useAppSelector(userInfoSelector);
  const dispatch = useAppDispatch();
  const googleToken = useAppSelector(googleTokenSelector);
  const sig = useAppSelector(walletSignSelector);
  const formErrors = useAppSelector(userFormErrorSelector) || {};
  const [errors, setErrors] = useState<any>({}); // {email: ['error1', 'error2']
  const [error, setError] = useState<String>();
  const [showPrivacy, setShowPrivacy] = useState<boolean>(false);
  const [otpSent, setOtpSent] = useState<boolean>(false);
  const postJson = usePostJson();
  const [remainingSecond, setRemainingSecond] = useState<number>(0);
  const [targetDate, setTargetDate] = useState<number>(0);
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const difference = targetDate - currentTime;

      if (difference > 0) {
        setRemainingSecond(Math.floor(difference / 1000));
      } else {
        clearInterval(interval);
        setRemainingSecond(0);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetDate]);

  const sendOtp = () => {
    postJson(API_SEND_OTP_EMAIL, {email: form.getFieldValue('email')}).then((res) => {
      if (res.success) {
        setOtpSent(true);
      } else if (res.errors) {
        setErrors(res.errors);
      }
    }).catch((err) => {
      notification.error({message: 'Error', description: err.message})
    });
  }

  const onLoginFinish = (value: any) => {
    if (googleToken) {
      dispatch(signupGoogle(value))
    } else if (sig) {
      dispatch(signupWallet(value))
    }
  };
  useEffect(() => {
    let fields: FieldData[] = [];
    let _errors = {...errors, ...formErrors};
    if (Object.keys(_errors).length > 0) {
      for (let key in _errors) {
        let fieldData: FieldData = {
          name: key, errors: _errors[key]
        }
        fields = fields.concat(fieldData);
      }
      form.setFields(fields);
    }
    if (formErrors.form) {
      setError(formErrors.form)
    }
  }, [formErrors, errors]);
  const [form] = Form.useForm();

  const isReferral = refID ? true : false;

  return <LoginFormContainer step={'user-info'}>
    <Form form={form} className={classNames('login-form', props.className)} name={'userModel'} layout={'vertical'}
          onFinish={onLoginFinish} initialValues={{email: userInfo.email, refID: refID}} onChange={(e: any) => {
      setEmail(form.getFieldValue('email'));
      if (form.getFieldValue('otp')?.length === 6) {
        form.submit();
      }
    }}>
      <Form.Item name={'provider'} initialValue={'signUp'} hidden={true}/>
      <Form.Item name={'accessToken'} initialValue={googleToken} hidden={true}/>
      <Form.Item name={'sig'} initialValue={sig} hidden={true}/>
      <Title level={2}>Some info to begin</Title>
      {error && <Form.Item>
        <Alert type={'error'} message={error}></Alert>
      </Form.Item>}
      <Row gutter={[16, 16]}>
        <Col sm={24} xs={24}>
          <Form.Item name={'email'}
                     wrapperCol={{xs: 24}}
                     rules={[{
                       type: 'email',
                       message: 'The input is not valid E-mail!'
                     }]}>
            <Input placeholder={'Email (Optional)'}/>
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item name={'refID'}
                     wrapperCol={{xs: 24}}
                     rules={[{required: false, message: ''}, {
                       pattern: /[a-zA-Z0-9]{8}/,
                       message: 'Referral ID invalid'
                     }]}>
             <Input placeholder={'Referral ID (Optional)'} disabled={isReferral}/> 
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item name={'agreeTermsAndConditions'} valuePropName="checked" rules={[{
            required: true, message: 'Please agree with Terms and Conditions', pattern: /^true$/
          }]}>
            <OmsCheckbox textColor={'text-color-white'}>I have read and agree to the <Link
              to={'#'}
              onClick={() => setShowPrivacy(true)}>Terms
              and Conditions</Link>.<br/>I agree to receive news, announcement, and promotional contents from One Magic
              School via email.</OmsCheckbox>
          </Form.Item>
        </Col>
        {(!otpSent && email?.length > 0) && <Col sm={24} xs={24}>
          <Form.Item className={'full-width'}>
            <Button type={'primary'} onClick={() => {
              sendOtp();
              setTargetDate(Date.now() + 30000);
            }}>Register</Button>
          </Form.Item>
        </Col>}
        {otpSent && <Col sm={24} xs={24}>
          <Form.Item className={'full-width'} name={'otp'}>
            <Input placeholder={'OTP Code'} suffix={
              remainingSecond > 0 && <Button type={'link'} disabled={true}>Resend OTP ({remainingSecond} s)</Button>
              || <Button type={'link'} onClick={() => {
                sendOtp();
                setTargetDate(Date.now() + 30000)
              }}>Resend OTP</Button>
            }/>
          </Form.Item>
        </Col>}
        {(otpSent || !email?.length) && <Col sm={24} xs={24}>
          <Form.Item className={'full-width'}>
            <Button type={'primary'} htmlType="submit">Register</Button>
          </Form.Item>
        </Col>}
      </Row>
      <Divider/>
      <Form.Item className={'full-width'}>
        Try with another account? <a onClick={() => dispatch(changeStep('login'))}>login!</a>
      </Form.Item>
      <Modal title={'One Studios Privacy Policy'} open={showPrivacy} onOk={() => setShowPrivacy(false)} width={1700}
             footer={[<Button onClick={() => setShowPrivacy(false)}>Confirm</Button>]} closable={false}>
        <TermsAndConditionsContent/>
      </Modal>
    </Form>
  </LoginFormContainer>;
};
