import './LoadingMask.scss';
import { useAppSelector } from '../../app/hooks';
import { requestStatusSelector } from '../UserForm/UseSlice';
import { gameLoadingSelector } from '../HomePhaser/HomePhaserSlice';
import { appLoadingStatusSelector } from '../../app/AppConfigSlice';
import { shardLoadingStatusSelector } from '../Shard/ShardSlice';
import { nftStatusSelector } from '../NFTBalance/NFTBalanceSlice';
import { web3LoadingSelector, apiLoadingSelector } from '../../app/AppSlice';

const LoadingMask = () => {
  const requestStatus = useAppSelector(requestStatusSelector);
  const gameLoading = useAppSelector(gameLoadingSelector);
  const appLoading = useAppSelector(appLoadingStatusSelector);
  const shardLoading = useAppSelector(shardLoadingStatusSelector);
  const nftLoadingStatus = useAppSelector(nftStatusSelector);
  const web3loading = useAppSelector(web3LoadingSelector);
  const apiLoading = useAppSelector(apiLoadingSelector);

  const isLoading = requestStatus === 'loading'
    || gameLoading || web3loading
    || appLoading === 'loading'
    || shardLoading === 'loading'
    || nftLoadingStatus === 'loading'
    || Object.keys(apiLoading).length;

  return isLoading && <div className={'loading-mask-container'}>
    <img src="/loading_shard.gif" className={'loading-mask-icon'}></img>
  </div> || <></>;
}

export default LoadingMask;