import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { API_Game_Config, API_User_Get_MineShardStatus } from '../helpers/config';

interface AffTier {
  _id: number,
  start_point: number,
  end_point: number,
  claim_amount: number
}

interface MineShardStatus {
  '_id': string,
  'remain': number,
  'pending': number,
  'mined': number,
  'total': number
}

interface ClamStatus {
  '_id': string,
  'user': string,
  'claim_amount': number,
  'claim_date': Date,
  'end': Date,
  'status': number
}

interface AppState {
  version?: number,
  status: 'loading' | 'ready',
  affTiers?: AffTier[],
  mineShardStatus?: MineShardStatus,
  clamStatus?: ClamStatus
}

const initialState: AppState = {status: 'ready'}

export const appConfigSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadAppConfig.fulfilled, (state, action) => {
      state.affTiers = action.payload.affTiers;
      state.version = action.payload.version;
    })
      .addCase(loadMineShardStatus.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(loadMineShardStatus.rejected, (state) => {
        state.status = 'ready'
      })
      .addCase(loadMineShardStatus.fulfilled, (state, action) => {
        state.mineShardStatus = action.payload.mineShardStatus;
        state.clamStatus =action.payload.clamStatus;
        state.status = 'ready';
      })
  }
});

export const loadAppConfig = createAsyncThunk('appConfig/load', async () => {
  const response = await fetch(API_Game_Config, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    mode: 'cors'
  });
  return response.json();
})

export const loadMineShardStatus = createAsyncThunk('appConfig/mineshardStatus', async () => {
  const response = await fetch(API_User_Get_MineShardStatus, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    mode: 'cors'
  });
  return response.json();
})

export default appConfigSlice.reducer;
export const {} = appConfigSlice.actions;
export const appConfigVersonSelector = (state: RootState) => state.appConfig.version;
export const clamStatusSelector = (state: RootState) => state.appConfig.clamStatus;
export const mineShardStatusSelector = (status: RootState) => status.appConfig.mineShardStatus;
export const affTiersSelector = (state: RootState) => state.appConfig.affTiers;
export const appLoadingStatusSelector = (state: RootState) => state.appConfig.status;