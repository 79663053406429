import { Checkbox, CheckboxProps } from 'antd';
import { TextColorType } from './Types';
import classNames from 'classnames';

interface OmsCheckboxProps extends CheckboxProps {
  textColor?: TextColorType;
}

const OmsCheckbox = (_props: OmsCheckboxProps) => {
  const {textColor, className, ...props} = _props;
  return <Checkbox className={classNames('oms-checkbox', textColor, className)} {...props}/>;
}

export default OmsCheckbox;