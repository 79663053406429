import { Button, Modal, Typography, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import './SChoolBuildPopup.scss';
import { postJSON } from 'helpers/common';
import { BUILD_MODULE } from 'helpers/config';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ModulesData, loadAllModulesSelector } from './SchoolModuleSlice';
import { buildNewModule, loadUserGameInfo, loadUserGameInfoSelector, userGameInfoData } from './gameUserInfoSlide';
import Text from 'components/Layout/Text';

interface SchoolBuildPopupProps {
  isOpen: boolean
  setIsOpen: Function,
  moduleId: string,
  setModuleId: Function
}

interface ResourceCostInfo {
  resourceName: string | undefined,
  resource_id: string,
  cost: number,
  isSufficient: boolean
}

interface RequiredModuleInfo {
  moduleName?: string,
  moduleId?: string,
  isAvailable: boolean
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const SchoolBuildPopup = ({ isOpen, setIsOpen, moduleId, setModuleId }: SchoolBuildPopupProps) => {
  const allModulesInfo = useAppSelector(loadAllModulesSelector);
  const gameUserInfo = useAppSelector(loadUserGameInfoSelector);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [choosedResourceCost, setChoosedResourceCost] = useState<ResourceCostInfo[] | undefined>(undefined);
  const [isSufficientForMining, setIsSufficientForMining] = useState<boolean>(true);
  const [moduleAvailableForBuild, setModuleAvailableForBuild] = useState<RequiredModuleInfo[] | undefined>(undefined);
  const dispatch = useAppDispatch();

  // const [buildModule, setBuildModule] = useState<ModulesData | undefined>(undefined);

  const [api, contextHolder] = notification.useNotification();
  notification.config({
    placement: 'topRight',
    duration: 3,
    rtl: true,
  })

  const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
    api[type]({
      message: message,
      description: description
    });
  };

  const module = allModulesInfo.find(module => module.id === moduleId);

  useEffect(() => {
    if (moduleId) {
      const module = allModulesInfo.find(module => module.id === moduleId);
      if (module) {
        setChoosedResourceCost(undefined);
        setIsSufficientForMining(true);
        
        const reqResource = module.required_resources.map(reqRes => {
          let isInsufficient = false;
          if (gameUserInfo.resources[reqRes.resource_id]) {
            if (reqRes.value > gameUserInfo.resources[reqRes.resource_id]) {
              isInsufficient = true;
              setIsSufficientForMining(false);
            }
          } else if (reqRes.value > 0) {
            isInsufficient = true;
            setIsSufficientForMining(false);
          }
          return {
            resourceName: reqRes.resource_name,
            resource_id: reqRes.resource_id,
            cost: reqRes.value,
            isSufficient: !isInsufficient
          }
        });
        setChoosedResourceCost(reqResource ? reqResource : undefined);
        
        setModuleAvailableForBuild(undefined);
        
        const reqModule = module.required_modules.map(reqModule => {
          let isAvailable = true;
          if (!gameUserInfo.modules[reqModule]) {
            isAvailable = false;
            setIsSufficientForMining(false);
          }
          const module = allModulesInfo.find(m => m.id === reqModule);
          return {
            moduleName: module?.name,
            moduleId: module?.id,
            isAvailable: isAvailable
          }
        });
        console.log(reqModule);
        setModuleAvailableForBuild(reqModule ? reqModule : undefined);
      }
    }
  }, [allModulesInfo, gameUserInfo, moduleId])

  const loadGameUserInfoData = async () => {
    try {
      return await dispatch(loadUserGameInfo());
    } catch (e) {
    }
  };



  const handleClose = () => {
    setChoosedResourceCost(undefined);
    setModuleAvailableForBuild(undefined);
    setIsSufficientForMining(true);
    setIsOpen(false);
    setModuleId('');
  }

  const checkIsSufficientResourceBeforeBuild = (gameUserInfo: userGameInfoData, miningModule: ModulesData): boolean => {
    let isInsufficient = false;
    miningModule.required_resources.forEach(reqRes => {
      if (gameUserInfo.resources[reqRes.resource_id]) {
        if (reqRes.value > gameUserInfo.resources[reqRes.resource_id]) {
          isInsufficient = true;
          return isInsufficient;
        }
      } else if (reqRes.value > 0) {
        isInsufficient = true;
        return isInsufficient;
      }
    })
    return !isInsufficient;
  }

  const checkIsRequiredModuleBeforeBuild = (gameUserInfo: userGameInfoData, miningModule: ModulesData): boolean => {
    let isInsufficient = false;
    miningModule.required_modules.forEach(reqModule => {
      if (!gameUserInfo.modules[reqModule]) {
        isInsufficient = true;
        return isInsufficient;
      }
    })
    return !isInsufficient;
  }

  const handleBuild = async () => {
    if (module) {
      const checkResourceEnough = checkIsSufficientResourceBeforeBuild(gameUserInfo, module);
      const checkModuleEnough = checkIsRequiredModuleBeforeBuild(gameUserInfo, module);
      if (checkResourceEnough && checkModuleEnough) {
        setIsLoading(true);
        const data = { module_id: moduleId };
        await postJSON(BUILD_MODULE, data).then(res => {
          if (res.error) {
            throw Error(res.error)
          }
          loadGameUserInfoData().then(res => {
            setIsLoading(false);
            dispatch(buildNewModule(data));
            setIsOpen(false);
            openNotificationWithIcon('success', 'Build Success', `Your ${module?.name} module is ready`);
          });

        }).catch(error => {
          setIsLoading(false);
          openNotificationWithIcon('info', 'Build Fail', error.message);
        }
        );
      } else {
        openNotificationWithIcon('error', 'Build failed', 'Insufficient Resources');
      }
    }
  }

  const styleRequired = {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center'
  };


  const RequiredModule = () => {
    return (
      <>
        <div><Text strong>Building Required</Text></div>
        {moduleAvailableForBuild?.length && moduleAvailableForBuild?.length > 0
          ? <div style={styleRequired}>
            {moduleAvailableForBuild?.map(module => (
              <Text style={{ color: module.isAvailable ? 'white' : 'red' }} fontSize='sm' key={module.moduleId}>{module.moduleName}</Text>
            ))}
          </div>
          : <Text fontSize='sm'>None</Text>
        }

      </>
    )
  }

  const RequiredResource = () => {
    return (
      <>
        <div><Text strong>Resource Required</Text></div>
        {choosedResourceCost?.length && choosedResourceCost?.length > 0
          ? <div style={styleRequired}>
            {choosedResourceCost?.map(resource => (
              <Text style={{ color: resource.isSufficient ? 'white' : 'red' }} fontSize='sm' key={resource.resource_id}>{resource.resourceName} : {resource.cost}</Text>
            ))}
          </div>
          : <Text fontSize='sm'>None</Text>
        }

      </>
    )
  }

  return (
    <div className='container'>
      {contextHolder}
      <Modal open={isOpen} footer={null} onCancel={() => handleClose()}
        centered={true} className={'building-modal'} destroyOnClose>
        <div className={'header-wrapper'} >
          <Text fontSize='lg' >{module?.name}</Text>
        </div>
        <div className={'body-wrapper'}>
          <RequiredResource />
          <RequiredModule />
        </div>
        <div className={'footer-wrapper'}>
          {isSufficientForMining
            ? <Button type={'primary'} size={'large'} loading={isLoading} onClick={() => handleBuild()}>Build Module</Button>
            : <Button type={'primary'} size={'large'} onClick={() => handleClose()}>Close</Button>
          }

        </div>
      </Modal>
    </div>
  )
}