import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import localStorage from 'redux-persist/es/storage';
import { persistReducer } from 'redux-persist';

interface SoundState {
  volumn: number,
  toggle: boolean
}

const initialState = {
  volumn: 100,
  toggle: true
}

const soundPersistConfig = {
  key: 'sound',
  storage: localStorage
}


const soundSlice = createSlice({
  name: 'sound',
  initialState: initialState,
  reducers: {
    toggleSwitch: (state) => {
      state.toggle = state.toggle ? false : true
    },
    changeVolumn: (state, action: PayloadAction<number>) => {
      state.volumn = action.payload
    },
  }
})

export default persistReducer(soundPersistConfig, soundSlice.reducer);
export const {toggleSwitch, changeVolumn} = soundSlice.actions;
export const soundToggleSelector = (state: RootState) => state.sound.toggle;
export const volumnSelector = (state: RootState) => state.sound.volumn;
