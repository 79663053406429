import { HTMLAttributes, useEffect } from 'react';
import LoginFormContainer from './LoginFormContainer';
import { Button, Form, Input, notification, Typography } from 'antd';
import classNames from 'classnames';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import './ResetPassword.scss';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  changeStep,
  requestStatusSelector,
  resetPassword, setUserFormErrorMessage,
  userFormErrorMessageSelector
} from './UseSlice';
import NotifyForm from './NotifyForm';

const {Title} = Typography;
const {Password} = Input;

interface ResetPasswordProp extends HTMLAttributes<HTMLDivElement> {

}

export default (_props: ResetPasswordProp) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams,] = useSearchParams();
  const _id = searchParams.get('id');
  const [form] = useForm();
  const requestStatus = useAppSelector(requestStatusSelector);
  const errorMessage = useAppSelector(userFormErrorMessageSelector);

  useEffect(() => {
    errorMessage && notification.error({message: errorMessage})
    dispatch(setUserFormErrorMessage(undefined));
  }, [errorMessage])
  return <LoginFormContainer step={'reset-password'}>
    {requestStatus !== 'rspw-success' &&
      <Form className={classNames('reset-password-form')} name={'userModel'} layout={'vertical'}
            form={form} onFinish={(values) => {
        dispatch(resetPassword(values));
      }} initialValues={{_id: _id}}>
        <Title level={1}>{_id && 'Reset password' || 'Change password'}</Title>
        <Form.Item name={'_id'} hidden={true}>
          <Input type={'hidden'}/>
        </Form.Item>
        <Form.Item name={'password'} label={'Password'}
                   wrapperCol={{sm: 24, xs: 24}}
                   rules={[{required: true, message: 'Please input your password!'},
                     {
                       min: 6,
                       message: 'Password min length is 6 characters'
                     },
                     {
                       max: 70,
                       message: 'Password too long'
                     },
                     {
                       pattern: /^(?=.*?[A-Z]).+$/,
                       message: 'Password must have at least one Uppercase'
                     },
                     {
                       pattern: /^(?=.*?[a-z]).+$/,
                       message: 'Password must have at least one lowercase'
                     },
                     {
                       pattern: /^(?=.*?[0-9]).+$/,
                       message: 'Password must have at least one numeric'
                     }, {
                       pattern: /^[A-Za-z0-9!@#$%^&*]+$/,
                       message: 'Password allow UPPERCASE, lowercase, numeric and special character !@#$%^&*'
                     },
                     {
                       validator: async (rule, value) => {
                         const repeatPassword = form.getFieldValue('repeatPassword');
                         if (!repeatPassword){
                           return;
                         }
                         if (value !== repeatPassword){
                           throw new Error('Password and Repeat Password not match');
                         }
                       },
                       message: 'Password and Repeat Password not match'
                     }]}>
          <Password placeholder={'Password'} key={'password'}
                    onChange={() => form.getFieldValue('repeatPassword') && form.validateFields(['repeatPassword'])}/>
        </Form.Item>
        <Form.Item name={'repeatPassword'} label={'Repeat Password'}
                   wrapperCol={{sm: 24, xs: 24}}
                   rules={[{required: true, message: 'Please input your password again!'},
                     {
                       validator: async (rule, value) => {
                         const password = form.getFieldValue('password');
                         if (!password){
                           return;
                         }
                         if (value !== password){
                           throw new Error('Password and Repeat Password not match');
                         }
                       },
                       message: 'Password and Repeat Password not match'
                     }]}>
          <Password placeholder={'Password'}
                    onChange={() => form.validateFields(['password'])}/>
        </Form.Item>
        <Form.Item className={'full-width'}>
          <Button type={'primary'} htmlType="submit" size={'large'}>Reset your password</Button>
        </Form.Item>
        <Form.Item>
          <Link to={'/login'}>Back to login</Link>
        </Form.Item>
      </Form> || <Form>
        <NotifyForm icon={'success'} className={'reset-password-form'}>
          <Title level={3}>Password reset</Title>
          <Button type={'primary'} onClick={() => {
            dispatch(changeStep('login'));
            navigate('/login')
          }}>Back to login</Button>
        </NotifyForm>
      </Form>
    }
  </LoginFormContainer>
}