import { initializeConnector } from '@web3-react/core'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'

import { ActiveNetworks } from 'helpers/config'



export const [walletConnect, hooks] = initializeConnector<WalletConnectV2>(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: '92f43d4490530a964c2560c7a3905632',
        chains: [ActiveNetworks.polygon],
        optionalChains:[],
        showQrModal: true,
      },
    })
)